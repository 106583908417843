@charset "UTF-8";
@import url(../fonts/icofont/style.css);
.selectric-wrapper {
  position: relative;
  cursor: pointer;
  margin-bottom: 15px; }

.selectric-responsive {
  width: 100%; }

.selectric {
  border: 0;
  border-bottom: 2px solid #000;
  border-radius: 0;
  background: #fff;
  position: relative;
  overflow: hidden; }
  .selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 36px 0 10px;
    font-size: 15px;
    line-height: 36px;
    color: #636c71;
    height: 36px;
    user-select: none; }
  .selectric .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 36px;
    height: 36px;
    line-height: 36px;
    background-color: #fff;
    color: #000;
    text-align: center;
    font: 0/0 a;
    *font: 20px/36px Lucida Sans Unicode, Arial Unicode MS, Arial;
    transition: all ease 200ms; }
    .selectric .button:after {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 30px;
      content: "\e91f";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
      color: #009641;
      font-weight: 600; }

.selectric-focus .selectric {
  border-bottom: 2px solid #009641; }

.selectric-open {
  z-index: 9999; }
  .selectric-open .selectric {
    border-bottom: 2px solid #009641; }
    .selectric-open .selectric .button {
      color: #00632b;
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
  .selectric-open .selectric-items {
    display: block; }

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0; }
  .selectric-hide-select select {
    position: absolute;
    left: -100%; }
  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .selectric-hide-select.selectric-is-native select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #009641;
  border: 1px solid #000;
  border-top: 0;
  z-index: -1;
  box-shadow: 0 0 10px -6px; }
  .selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto; }
  .selectric-above .selectric-items {
    top: auto;
    bottom: 100%; }
  .selectric-items ul, .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    min-height: 20px; }
  .selectric-items li {
    display: block;
    padding: 10px;
    color: #636c71;
    background: #fff;
    cursor: pointer;
    transition: all ease 200ms; }
    .selectric-items li.selected {
      background: #009641;
      color: #fff; }
    .selectric-items li:hover {
      background: #009641;
      color: #fff; }
  .selectric-items .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    user-select: none; }
  .selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 10px;
    cursor: default;
    user-select: none;
    background: none;
    color: #444; }
  .selectric-items .selectric-group.disabled li {
    filter: alpha(opacity=100);
    opacity: 1; }
  .selectric-items .selectric-group li {
    padding-left: 25px; }

.pux-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  max-width: 100%;
  height: 100%; }
  @media (min-width: 1200px) {
    .pux-container {
      max-width: 1320px;
      padding-left: 50px;
      padding-right: 50px; } }
  .pux-container:after {
    display: block;
    content: "";
    clear: both; }

@media (min-width: 480px) and (max-width: 544px) {
  .col-xs-five-grid {
    -webkit-flex: 0 0 20%;
    -moz-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 545px) and (max-width: 767px) {
  .col-sm-five-grid {
    -webkit-flex: 0 0 20%;
    -moz-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 768px) and (max-width: 991px) {
  .col-md-five-grid {
    -webkit-flex: 0 0 20%;
    -moz-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .col-lg-five-grid {
    -webkit-flex: 0 0 20%;
    -moz-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 1200px) and (max-width: 1399) {
  .col-xl-five-grid {
    -webkit-flex: 0 0 20%;
    -moz-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

.flex-as-end {
  -webkit-align-self: flex-end;
  -moz-align-self: flex-end;
  -ms-align-self: flex-end;
  -webkit-align-self: flex-end;
  align-self: flex-end; }

.flex-box {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

.jc-space {
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between; }

.ai-center {
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; }

.full-container {
  max-width: 1290px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  padding-bottom: 1px; }
  .full-container:after {
    display: block;
    content: "";
    clear: both; }

.header-helper {
  height: 50px;
  width: 100%; }

header {
  position: fixed;
  top: 0;
  left: 50%;
  right: 0;
  margin: 0 auto;
  z-index: 999;
  max-width: 1290px;
  background-color: #000;
  width: 100%;
  transform: translateX(-50%); }
  header .logo {
    padding: 8px 13px 8px 0; }
    header .logo a {
      display: inline-block; }
    header .logo img {
      height: 34px; }
    header .logo span,
    header .logo h1 {
      display: none; }
  header .main-menu {
    width: 100%;
    position: absolute;
    top: 50px;
    left: 0;
    display: none;
    background: #000;
    max-height: -webkit-calc(100vh - 50px);
    max-height: -moz-calc(100vh - 50px);
    max-height: calc(100vh - 50px);
    overflow: auto; }
    header .main-menu ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
      @media (min-width: 992px) {
        header .main-menu ul {
          padding-right: 30px;
          -webkit-box-pack: space-between;
          -moz-box-pack: space-between;
          -ms-flex-pack: space-between;
          -webkit-justify-content: space-between;
          justify-content: space-between; } }
      @media (min-width: 1400px) {
        header .main-menu ul {
          padding-right: 50px; } }
      header .main-menu ul > li {
        position: relative; }
        header .main-menu ul > li:not(:last-of-type) {
          border-bottom: 1px solid #fff; }
        header .main-menu ul > li > a {
          cursor: pointer;
          display: block;
          font-size: 17px;
          font-size: 1.0625rem;
          line-height: 1;
          position: relative;
          padding: 18px 15px;
          color: #fff;
          text-decoration: none;
          font-weight: 400;
          letter-spacing: .04rem; }
          header .main-menu ul > li > a > span {
            display: inline-block;
            position: relative; }
            header .main-menu ul > li > a > span:after {
              display: block;
              content: "";
              height: 2px;
              left: 0;
              top: 100%;
              margin-top: 4px;
              width: 100%;
              background: #f7403b;
              opacity: 0;
              -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
              filter: alpha(opacity=0);
              visibility: hidden;
              -webkit-transition-property: opacity, visibility;
              -moz-transition-property: opacity, visibility;
              -o-transition-property: opacity, visibility;
              transition-property: opacity, visibility;
              -webkit-transition-duration: 250ms;
              -moz-transition-duration: 250ms;
              -o-transition-duration: 250ms;
              transition-duration: 250ms;
              -webkit-transition-timing-function: ease;
              -moz-transition-timing-function: ease;
              -o-transition-timing-function: ease;
              transition-timing-function: ease;
              position: absolute; }
          @media (min-width: 992px) {
            header .main-menu ul > li > a:focus span:after, header .main-menu ul > li > a:hover span:after {
              opacity: 1;
              -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
              filter: alpha(opacity=100);
              visibility: visible; } }
          @media (min-width: 992px) {
            header .main-menu ul > li > a {
              font-size: 14px;
              font-size: 0.875rem; } }
          @media (min-width: 1200px) {
            header .main-menu ul > li > a {
              padding: 18px 22px; } }
        header .main-menu ul > li.Highlighted > a span:after {
          opacity: 1;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
          filter: alpha(opacity=100);
          visibility: visible; }
        @media (min-width: 992px) {
          header .main-menu ul > li {
            border: none !important;
            position: static; } }
        header .main-menu ul > li:hover .submenu {
          opacity: 1;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
          filter: alpha(opacity=100);
          visibility: visible; }
        header .main-menu ul > li .menu-mobile {
          position: absolute;
          right: 0;
          top: 0;
          width: 65px;
          height: 53px;
          text-align: center;
          background: none;
          color: #fff; }
          header .main-menu ul > li .menu-mobile:before {
            content: "";
            width: 26px;
            height: 2px;
            background: #fff;
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -1px;
            margin-left: -13px; }
          header .main-menu ul > li .menu-mobile:after {
            content: "";
            width: 2px;
            height: 26px;
            background: #fff;
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -13px;
            margin-left: -1px;
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100);
            visibility: visible;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms; }
          header .main-menu ul > li .menu-mobile.open:after {
            -ms-transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
          @media (min-width: 992px) {
            header .main-menu ul > li .menu-mobile {
              display: none; } }
      @media (min-width: 992px) {
        header .main-menu ul {
          -webkit-flex-direction: row;
          -moz-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center; } }
    @media (min-width: 992px) {
      header .main-menu {
        position: relative;
        top: 0;
        overflow: visible;
        display: block !important; } }
  header .header {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    background: #000; }
    header .header-search {
      position: absolute;
      width: 100%;
      background: #000;
      padding: 0 10px;
      left: 0;
      top: 50px;
      height: 0;
      overflow: hidden;
      -moz-transition: all ease-in-out 300ms;
      -o-transition: all ease-in-out 300ms;
      -webkit-transition: all ease-in-out 300ms;
      transition: all ease-in-out 300ms;
      /*
            .searchBox{
                position:relative;
                &:before{
                    content:"\e909";
                    @include font-size(20px);
                    line-height:32px;
                    position:absolute;
                    bottom:0;
                    right:5px;
                }
            }*/ }
      @media (min-width: 992px) {
        header .header-search {
          position: static;
          max-width: 130px;
          overflow: visible;
          height: auto !important;
          padding: 0; } }
      header .header-search label {
        display: none; }
      header .header-search input[type="text"] {
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        border-radius: 0px;
        width: 100%;
        height: 32px;
        padding: 0 35px 0 10px;
        border: none;
        outline: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none; }
        header .header-search input[type="text"]:focus {
          border: none;
          outline: 0;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none; }
      header .header-search input[type="submit"] {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 32px;
        width: 32px;
        z-index: 22;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        border-radius: 0px;
        background: transparent;
        font-size: 0;
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0); }
        header .header-search input[type="submit"]:focus {
          border: none;
          outline: 0;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none; }
      header .header-search .btn-search {
        position: absolute;
        top: 10px;
        right: 0;
        font-size: 0;
        width: 30px;
        text-align: center; }
        header .header-search .btn-search:before {
          content: "\e909";
          font-size: 20px;
          font-size: 1.25rem;
          line-height: 32px;
          color: #000; }
        @media (min-width: 992px) {
          header .header-search .btn-search {
            top: 0px; } }
      header .header-search-inner {
        position: relative;
        padding: 10px 0 15px 0; }
        @media (min-width: 992px) {
          header .header-search-inner {
            padding: 0; } }
    header .header-login {
      position: absolute;
      background: #fff;
      top: 50px;
      right: 0;
      width: 100%;
      display: none; }
      header .header-login-inner {
        position: relative;
        padding: 10px;
        border: 2px solid #000;
        border-top: 0; }
        header .header-login-inner input[type=text],
        header .header-login-inner input[type=password] {
          background-color: #fff;
          width: 100%;
          padding: 8px 10px;
          height: 38px;
          color: #000;
          margin-bottom: 15px;
          border-radius: 0;
          position: relative;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          font-weight: 200;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          border: 0;
          border-bottom: 2px solid #000; }
          header .header-login-inner input[type=text]:focus,
          header .header-login-inner input[type=password]:focus {
            border-bottom: 2px solid #009641;
            outline: 0; }
        header .header-login-inner .logon-remember-me-checkbox {
          display: none; }
        header .header-login-inner .login-btn {
          text-align: right; }
        header .header-login-inner a {
          margin-top: 10px;
          display: inline-block; }
          @media (min-width: 992px) {
            header .header-login-inner a {
              font-size: 16px;
              font-size: 1rem; } }
        header .header-login-inner .logon-panel-password-retrieval > div:first-of-type {
          margin-top: 10px; }
      @media (min-width: 992px) {
        header .header-login {
          right: 100px;
          width: 360px; } }
    header .header-nav {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; }
      header .header-nav a {
        width: 32px;
        height: 32px;
        text-align: center;
        display: block;
        color: #fff !important;
        text-decoration: none;
        font-weight: 400;
        margin: 0 0 0 10px; }
        @media (min-width: 992px) {
          header .header-nav a {
            width: auto;
            height: auto; } }
      header .header-nav-search {
        font-size: 0; }
        header .header-nav-search:before {
          content: "\e909";
          font-size: 20px;
          font-size: 1.25rem;
          line-height: 32px;
          color: #fff; }
          @media (min-width: 992px) {
            header .header-nav-search:before {
              display: none; } }
      header .header-nav-login {
        margin: 0 2px;
        font-size: 0;
        position: relative;
        -webkit-transition-property: background-color, color;
        -moz-transition-property: background-color, color;
        -o-transition-property: background-color, color;
        transition-property: background-color, color;
        -webkit-transition-duration: 250ms;
        -moz-transition-duration: 250ms;
        -o-transition-duration: 250ms;
        transition-duration: 250ms;
        -webkit-transition-timing-function: ease;
        -moz-transition-timing-function: ease;
        -o-transition-timing-function: ease;
        transition-timing-function: ease;
        color: #fff; }
        @media (min-width: 992px) {
          header .header-nav-login:focus, header .header-nav-login:hover {
            color: #000 !important;
            background: #fff; } }
        header .header-nav-login:before {
          content: "\e90f";
          font-size: 20px;
          font-size: 1.25rem;
          line-height: 32px;
          color: #fff; }
          @media (min-width: 992px) {
            header .header-nav-login:before {
              display: none; } }
        @media (min-width: 992px) {
          header .header-nav-login {
            padding: 7px;
            margin: 0 8px;
            font-size: 14px;
            font-size: 0.875rem;
            width: 84px !important;
            text-align: center;
            line-height: 1;
            border: 2px solid #fff;
            color: #fff;
            border: 2px solid #fff; } }
      header .header-nav-language {
        font-size: 0;
        margin: 0 2px;
        color: #fff;
        margin-left: 10px;
        display: none; }
        header .header-nav-language:after {
          content: "\e905";
          font-size: 20px;
          font-size: 1.25rem;
          line-height: 32px;
          color: #fff; }
          @media (min-width: 992px) {
            header .header-nav-language:after {
              margin: 0 0 0 5px;
              vertical-align: -4px;
              line-height: normal; } }
        @media (min-width: 992px) {
          header .header-nav-language {
            width: 100px !important;
            display: block;
            margin: 0 8px;
            font-size: 14px;
            font-size: 0.875rem;
            padding: 0px 0 0 20px;
            text-align: right; } }
      header .header-nav-menu {
        position: relative; }
        header .header-nav-menu span {
          width: 26px;
          position: absolute;
          top: 50%;
          margin-top: -1px;
          background: #fff;
          height: 2px;
          left: 50%;
          margin-left: -13px;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          header .header-nav-menu span:before, header .header-nav-menu span:after {
            content: "";
            width: 26px;
            height: 2px;
            position: absolute;
            display: block;
            background: #fff;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms; }
          header .header-nav-menu span:before {
            top: -8px; }
          header .header-nav-menu span:after {
            bottom: -8px; }
        @media (min-width: 992px) {
          header .header-nav-menu {
            display: none !important; } }
        header .header-nav-menu.open span {
          background: transparent; }
          header .header-nav-menu.open span:before {
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            top: 0; }
          header .header-nav-menu.open span:after {
            -ms-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 0; }
    @media (min-width: 992px) {
      header .header {
        -webkit-box-pack: flex-start;
        -moz-box-pack: flex-start;
        -ms-flex-pack: flex-start;
        -webkit-justify-content: flex-start;
        justify-content: flex-start; } }
  header .submenu {
    font-size: 0;
    white-space: nowrap;
    background: #fff;
    z-index: 5;
    display: none;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    @media (min-width: 992px) {
      header .submenu {
        background: #000; } }
    header .submenu ul {
      display: block;
      width: 100%;
      min-width: 187px;
      white-space: normal;
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 18px;
      line-height: 1.125rem;
      border-top: 1px solid #000; }
      header .submenu ul li {
        display: block;
        border-bottom: 1px solid #000; }
        header .submenu ul li a {
          display: block;
          padding: 9px 15px;
          position: relative;
          font-weight: 400;
          color: #000; }
          header .submenu ul li a:hover {
            color: #f51a14; }
          @media (min-width: 992px) {
            header .submenu ul li a {
              padding: 9px 0;
              color: #fff;
              border-bottom: 1px solid #fff; } }
        header .submenu ul li span {
          display: block;
          font-weight: 600;
          padding: 9px 15px;
          background: #fff;
          color: #009641;
          border-bottom: 1px solid #fff; }
          @media (min-width: 992px) {
            header .submenu ul li span {
              background: #000;
              color: #000;
              padding: 9px 0; } }
        header .submenu ul li:last-child {
          border: none; }
        header .submenu ul li ul {
          margin: 0;
          padding: 0;
          width: auto;
          min-width: 10px; }
      @media (min-width: 992px) {
        header .submenu ul {
          width: 187px;
          padding: 0 15px;
          border: none; } }
    @media (min-width: 992px) {
      header .submenu {
        position: absolute;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        padding: 5px 7px 20px;
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        visibility: hidden;
        -webkit-transition-property: opacity, visibility;
        -moz-transition-property: opacity, visibility;
        -o-transition-property: opacity, visibility;
        transition-property: opacity, visibility;
        -webkit-transition-duration: 250ms;
        -moz-transition-duration: 250ms;
        -o-transition-duration: 250ms;
        transition-duration: 250ms;
        -webkit-transition-timing-function: ease;
        -moz-transition-timing-function: ease;
        -o-transition-timing-function: ease;
        transition-timing-function: ease;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex; } }

.loader {
  display: none;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #f7403b 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #f7403b 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #f7403b 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #f7403b 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #f7403b 10%, rgba(255, 255, 255, 0) 42%);
  position: absolute;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 33;
  left: 50%;
  margin-left: -60px; }

.loader:before {
  width: 50%;
  height: 50%;
  background: #f7403b;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: ''; }

.loader:after {
  background: #fff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.quick-search.opacity .loader {
  display: inline-block;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100); }

.quick-search.opacity .quick-search-inner {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  max-height: 293px;
  overflow: hidden; }
  .quick-search.opacity .quick-search-inner header {
    display: none; }

.quick-search {
  position: fixed;
  background: #e0e0e0;
  top: 106px;
  left: 0;
  width: 100%;
  background: #fff;
  visibility: hidden;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  border: 1px solid #000; }
  @media (min-width: 992px) {
    .quick-search {
      position: absolute;
      top: 40px;
      width: 500px;
      right: -170px;
      left: auto; } }
  .quick-search-inner {
    padding-bottom: 10px;
    min-height: 293px;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .quick-search .header-search-item {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    color: #000;
    text-decoration: none !important;
    border-bottom: 1px solid #000;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    .quick-search .header-search-item-image {
      position: relative;
      width: 130px;
      min-width: 130px;
      height: 90px;
      background: #fff;
      text-align: center;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      overflow: hidden; }
    .quick-search .header-search-item-name {
      font-weight: bold;
      padding: 0 15px;
      width: 100%;
      font-size: 18px;
      font-size: 1.125rem; }
    .quick-search .header-search-item-arrow {
      width: 30px;
      height: 30px;
      min-width: 30px;
      background: transparent;
      color: #000;
      margin-right: 10px;
      text-align: center;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      -webkit-transition-property: background, border-color, color;
      -moz-transition-property: background, border-color, color;
      -o-transition-property: background, border-color, color;
      transition-property: background, border-color, color;
      -webkit-transition-duration: 250ms;
      -moz-transition-duration: 250ms;
      -o-transition-duration: 250ms;
      transition-duration: 250ms;
      -webkit-transition-timing-function: ease;
      -moz-transition-timing-function: ease;
      -o-transition-timing-function: ease;
      transition-timing-function: ease; }
      .quick-search .header-search-item-arrow:before {
        content: "\e91f";
        line-height: 28px;
        font-size: 24px;
        font-size: 1.5rem;
        margin-left: -2px; }
    .quick-search .header-search-item:focus, .quick-search .header-search-item:hover {
      background: #f7403b;
      color: #fff; }
      .quick-search .header-search-item:focus .header-search-item-arrow, .quick-search .header-search-item:hover .header-search-item-arrow {
        color: #fff; }
  .quick-search.open {
    visibility: visible;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }
  .quick-search .btn-search-more {
    color: #000; }
    .quick-search .btn-search-more:hover, .quick-search .btn-search-more:focus {
      color: #fff !important;
      background-color: #f7403b;
      border-color: #f7403b; }

.quick-search-btn {
  padding: 10px 10px 20px 10px; }

.submenu-full .submenu {
  left: 0; }

footer {
  max-width: 1290px;
  margin: auto;
  background: #000;
  color: #fff;
  padding: 20px 0;
  margin-top: 0;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem; }
  footer .nwl-title {
    margin-bottom: 20px; }
  footer .nwl-box label {
    display: none; }
  footer .nwl-box .form-horizontal {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: flex-start;
    -moz-box-align: flex-start;
    -ms-flex-align: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    @media (min-width: 768px) {
      footer .nwl-box .form-horizontal {
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row; } }
    footer .nwl-box .form-horizontal .form-group {
      width: 100%; }
      @media (min-width: 768px) {
        footer .nwl-box .form-horizontal .form-group {
          width: auto; } }
  footer .nwl-box input[type="text"] {
    width: 100%;
    margin-bottom: 0;
    padding: 0 5px;
    height: 26px;
    line-height: 24px;
    font-size: 14px;
    color: #fff;
    background: #000;
    border: 1px solid #fff;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    -webkit-transition-property: border-color;
    -moz-transition-property: border-color;
    -o-transition-property: border-color;
    transition-property: border-color;
    -webkit-transition-duration: 250ms;
    -moz-transition-duration: 250ms;
    -o-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease; }
    footer .nwl-box input[type="text"]:focus {
      border-color: #fff; }
    footer .nwl-box input[type="text"]:-moz-placeholder {
      color: #fff; }
    footer .nwl-box input[type="text"]::-moz-placeholder {
      color: #fff; }
    footer .nwl-box input[type="text"]::-webkit-input-placeholder {
      color: #fff; }
    footer .nwl-box input[type="text"]:-ms-input-placeholder {
      color: #fff; }
    @media (min-width: 768px) {
      footer .nwl-box input[type="text"] {
        width: 200px; } }
  footer .nwl-box input[type="submit"] {
    cursor: pointer;
    display: block;
    float: right;
    width: 100%;
    margin-left: 10px;
    font-size: 14px;
    height: 26px;
    outline: none;
    text-align: center;
    text-decoration: none;
    padding: 0 !important;
    color: #fff;
    background: #000;
    border: 1px solid #fff;
    font-weight: bold;
    -webkit-transition-property: background, border-color, color;
    -moz-transition-property: background, border-color, color;
    -o-transition-property: background, border-color, color;
    transition-property: background, border-color, color;
    -webkit-transition-duration: 250ms;
    -moz-transition-duration: 250ms;
    -o-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease; }
    footer .nwl-box input[type="submit"]:hover, footer .nwl-box input[type="submit"].active {
      border-color: #fff;
      color: #000;
      background: #fff; }
    @media (min-width: 768px) {
      footer .nwl-box input[type="submit"] {
        width: 85px; } }
  footer .nwl-bottom {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    footer .nwl-bottom .social {
      font-size: 0; }
      footer .nwl-bottom .social .ico-social {
        display: inline-block;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        margin: 5px;
        height: 27px;
        width: 27px;
        text-decoration: none !important;
        position: relative; }
        footer .nwl-bottom .social .ico-social:before {
          color: #fff;
          -webkit-transition-property: color;
          -moz-transition-property: color;
          -o-transition-property: color;
          transition-property: color;
          -webkit-transition-duration: 250ms;
          -moz-transition-duration: 250ms;
          -o-transition-duration: 250ms;
          transition-duration: 250ms;
          -webkit-transition-timing-function: ease;
          -moz-transition-timing-function: ease;
          -o-transition-timing-function: ease;
          transition-timing-function: ease;
          font-size: 27px;
          font-size: 1.6875rem;
          position: absolute;
          top: 0;
          left: 0; }
        footer .nwl-bottom .social .ico-social:focus:before, footer .nwl-bottom .social .ico-social:hover:before {
          color: #f7403b; }
        footer .nwl-bottom .social .ico-social:first-child {
          margin: 5px 5px 5px 0; }
          @media (min-width: 768px) {
            footer .nwl-bottom .social .ico-social:first-child {
              margin: 5px; } }
        footer .nwl-bottom .social .ico-social.s-google:before {
          content: ""; }
        footer .nwl-bottom .social .ico-social.s-tumblr:before {
          content: ""; }
        footer .nwl-bottom .social .ico-social.s-facebook:before {
          content: ""; }
        footer .nwl-bottom .social .ico-social.s-mail:before {
          content: ""; }
        footer .nwl-bottom .social .ico-social.s-twitter:before {
          content: ""; }
        footer .nwl-bottom .social .ico-social.s-linkedin:before {
          content: ""; }
        footer .nwl-bottom .social .ico-social.s-vine:before {
          content: ""; }
        footer .nwl-bottom .social .ico-social.s-youtube:before {
          content: ""; }
    footer .nwl-bottom .copyright {
      padding-bottom: 10px;
      font-size: 10px;
      font-size: 0.625rem;
      line-height: 14px;
      line-height: 0.875rem; }
      @media (min-width: 768px) {
        footer .nwl-bottom .copyright {
          padding-right: 10px; } }
      @media (min-width: 992px) {
        footer .nwl-bottom .copyright {
          padding-right: 85px;
          padding-bottom: 0; } }
    @media (min-width: 768px) {
      footer .nwl-bottom {
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row; } }
  footer ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    footer ul li {
      display: inline-block;
      vertical-align: top;
      margin: 0 5px 0 -5px;
      font-size: 10px;
      font-size: 0.625rem;
      line-height: 14px;
      line-height: 0.875rem; }
      footer ul li a {
        display: block;
        padding: 5px;
        color: #fff;
        text-decoration: none; }
        footer ul li a:hover {
          color: #fff;
          text-decoration: underline; }

.bottom-logo img {
  width: 90px;
  height: auto; }
  @media (min-width: 992px) {
    .bottom-logo img {
      width: 132px; } }

.bottom-logo-text {
  margin-top: 5px;
  font-size: 8px;
  font-size: 0.5rem;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    .bottom-logo-text {
      font-size: 10px;
      font-size: 0.625rem; } }

.block-50 {
  margin: 40px 0; }
  @media (min-width: 768px) {
    .block-50 {
      margin: 50px 0; } }

.block-60 {
  margin: 40px 0; }
  @media (min-width: 768px) {
    .block-60 {
      margin: 60px 0; } }

.block-large {
  margin-top: 40px;
  margin-bottom: 40px; }
  @media (min-width: 768px) {
    .block-large {
      margin-top: 60px;
      margin-bottom: 60px; } }
  @media (min-width: 992px) {
    .block-large {
      margin-top: 120px;
      margin-bottom: 70px; } }
  @media (min-width: 1400px) {
    .block-large {
      margin-top: 150px;
      margin-bottom: 90px; } }
  .block-large-wth-title {
    margin-top: 20px; }
    @media (min-width: 768px) {
      .block-large-wth-title {
        margin-top: 30px;
        margin-bottom: 60px; } }
    @media (min-width: 992px) {
      .block-large-wth-title {
        margin-top: 60px;
        margin-bottom: 70px; } }
    @media (min-width: 1400px) {
      .block-large-wth-title {
        margin-top: 100px;
        margin-bottom: 90px; } }
    .block-large-wth-title > .row > div:first-of-type {
      padding-top: 20px; }
      @media (min-width: 768px) {
        .block-large-wth-title > .row > div:first-of-type {
          padding-top: 50px; } }
      @media (min-width: 992px) {
        .block-large-wth-title > .row > div:first-of-type {
          padding-top: 60px; } }
      @media (min-width: 1400px) {
        .block-large-wth-title > .row > div:first-of-type {
          padding-top: 70px; } }

.style-red {
  color: #f7403b; }

.items-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.base-padding {
  padding: 0 15px; }
  @media (min-width: 1200px) {
    .base-padding {
      padding: 0 50px; } }

.mt-20 {
  margin-top: 20px; }

.base-margin-top {
  margin-top: 30px; }
  @media (min-width: 768px) {
    .base-margin-top {
      margin-top: 40px; } }
  @media (min-width: 1400px) {
    .base-margin-top {
      margin-top: 50px; } }

main {
  min-height: 80vh; }

.IE11 .logo img {
  width: 124px; }

.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
  font-family: 'Core-icons' !important; }

*:before,
*:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@font-face {
  font-family: "Druk-Wide";
  src: url("../fonts/DrukWideMedium.woff") format("woff"), url("../fonts/DrukWideMedium.otf");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "GT-America";
  src: url("../fonts/GTAmerica-Bold.woff") format("woff"), url("../fonts/GTAmerica-Bold.otf");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "GT-America";
  src: url("../fonts/GTAmerica-Regular.woff") format("woff"), url("../fonts/GTAmerica-Regular.otf");
  font-weight: 400;
  font-style: normal; }

body,
input,
select,
textarea {
  font-family: 'GT-America';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-size: 0.875rem; }

body {
  background-position: center;
  background-size: cover;
  background-attachment: fixed; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: 400; }

.h1, .h2, .h3, .h4, .h5, .h6 {
  display: block; }

h1, .h1 {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 34px;
  line-height: 2.125rem; }
  @media (min-width: 545px) {
    h1, .h1 {
      font-size: 33px;
      font-size: 2.0625rem;
      line-height: 37px;
      line-height: 2.3125rem; } }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 35px;
      font-size: 2.1875rem;
      line-height: 39px;
      line-height: 2.4375rem; } }
  @media (min-width: 992px) {
    h1, .h1 {
      font-size: 42px;
      font-size: 2.625rem;
      line-height: 46px;
      line-height: 2.875rem; } }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 46px;
      font-size: 2.875rem;
      line-height: 50px;
      line-height: 3.125rem; } }
  h1.wide, .h1.wide {
    font-family: 'Druk-Wide';
    color: #009641;
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 32px;
    line-height: 2rem;
    line-height: 36px; }
    @media (min-width: 768px) {
      h1.wide, .h1.wide {
        font-size: 32px;
        font-size: 2rem;
        line-height: 36px;
        line-height: 2.25rem;
        line-height: 38px; } }
    @media (min-width: 992px) {
      h1.wide, .h1.wide {
        font-size: 36px;
        font-size: 2.25rem;
        line-height: 40px;
        line-height: 2.5rem;
        line-height: 43px; } }

h2, .h2 {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 28px;
  line-height: 1.75rem; }
  @media (min-width: 545px) {
    h2, .h2 {
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 32px;
      line-height: 2rem; } }
  @media (min-width: 768px) {
    h2, .h2 {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 34px;
      line-height: 2.125rem; } }
  @media (min-width: 992px) {
    h2, .h2 {
      font-size: 32px;
      font-size: 2rem;
      line-height: 36px;
      line-height: 2.25rem; } }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 36px;
      font-size: 2.25rem;
      line-height: 40px;
      line-height: 2.5rem; } }
  h2.small, .h2.small {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 26px;
    line-height: 1.625rem; }
    @media (min-width: 768px) {
      h2.small, .h2.small {
        font-size: 24px;
        font-size: 1.5rem;
        line-height: 28px;
        line-height: 1.75rem; } }
    @media (min-width: 992px) {
      h2.small, .h2.small {
        font-size: 26px;
        font-size: 1.625rem;
        line-height: 30px;
        line-height: 1.875rem; } }
    @media (min-width: 1200px) {
      h2.small, .h2.small {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 32px;
        line-height: 2rem; } }
  h2.wide, .h2.wide {
    font-family: 'Druk-Wide';
    color: #009641;
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 28px;
    line-height: 1.75rem; }
    @media (min-width: 545px) {
      h2.wide, .h2.wide {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 32px;
        line-height: 2rem; } }
    @media (min-width: 768px) {
      h2.wide, .h2.wide {
        font-size: 30px;
        font-size: 1.875rem;
        line-height: 34px;
        line-height: 2.125rem; } }
    @media (min-width: 992px) {
      h2.wide, .h2.wide {
        font-size: 32px;
        font-size: 2rem;
        line-height: 36px;
        line-height: 2.25rem; } }
    @media (min-width: 1200px) {
      h2.wide, .h2.wide {
        font-size: 36px;
        font-size: 2.25rem;
        line-height: 40px;
        line-height: 2.5rem; } }

h3, .h3 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 20px;
  line-height: 1.25rem; }
  @media (min-width: 992px) {
    h3, .h3 {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 24px;
      line-height: 1.5rem; } }

.password-strength-weak {
  color: #B22222; }

.password-strength-acceptable {
  color: #F4A460; }

.password-strength-average {
  color: #4169E1; }

.password-strength-strong {
  color: #3CB371; }

.password-strength-excellent {
  color: #228B22; }

strong,
.bold,
b {
  font-weight: 600; }

.semibold {
  font-weight: 600; }

a {
  font-family: 'GT-America';
  color: #000;
  text-decoration: none !important; }
  a:hover, a:focus {
    color: #f7403b; }

.no-href {
  font-family: 'GT-America';
  color: #000;
  text-decoration: none !important;
  cursor: pointer; }
  .no-href:hover, .no-href:focus {
    color: #f7403b !important; }

.title-line {
  padding-bottom: 5px;
  border-bottom: 2px solid #000;
  margin-bottom: 20px; }

.title-line-slim {
  font-weight: bold;
  padding-bottom: 3px;
  border-bottom: 1px solid #000;
  margin-bottom: 5px; }

.title-line-respons {
  padding-bottom: 0px;
  border-bottom: 1px solid #000;
  margin-bottom: 3px; }
  @media (min-width: 480px) {
    .title-line-respons {
      border-bottom: 2px solid #000;
      margin-bottom: 5px; } }

.square-button,
.square-button-bw {
  display: inline-block;
  padding: 5px;
  margin: 0 2px;
  margin-top: 15px;
  position: relative;
  border: 2px solid #000;
  -webkit-transition-property: background-color, color;
  -moz-transition-property: background-color, color;
  -o-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  -o-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  color: #000;
  text-align: center; }
  .square-button:focus, .square-button:hover,
  .square-button-bw:focus,
  .square-button-bw:hover {
    color: #fff !important;
    background: #f7403b;
    border: 2px solid #f7403b;
    text-decoration: none !important; }

.square-button-bw:focus, .square-button-bw:hover {
  color: #fff !important;
  background: #000; }

.big-button-bw,
.big-button-bw-small,
.arrow-down-link,
.btn-show-more {
  width: auto;
  padding: 0 15px;
  min-height: 40px;
  line-height: 38px;
  vertical-align: middle;
  display: inline-block;
  font-size: 18px;
  border: 2px solid #000;
  color: #000;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  text-align: center;
  -webkit-transition-property: background, color;
  -moz-transition-property: background, color;
  -o-transition-property: background, color;
  transition-property: background, color;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  -o-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease; }
  .big-button-bw:hover, .big-button-bw:focus,
  .big-button-bw-small:hover,
  .big-button-bw-small:focus,
  .arrow-down-link:hover,
  .arrow-down-link:focus,
  .btn-show-more:hover,
  .btn-show-more:focus {
    color: #fff !important;
    background: #f7403b;
    text-decoration: none;
    border: 2px solid #f7403b; }

.big-button-bw-small,
.arrow-down-link {
  font-size: 15px;
  padding: 0 10px; }
  @media (min-width: 768px) {
    .big-button-bw-small,
    .arrow-down-link {
      font-size: 18px;
      font-size: 1.125rem;
      padding: 0 20px; } }

.btn {
  display: inline-block;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  border: none;
  padding: 10px 15px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  white-space: normal !important;
  max-width: 100%; }
  @media (min-width: 768px) {
    .btn {
      width: 250px; } }
  @media (min-width: 1200px) {
    .btn {
      width: 335px; } }
  .btn:focus, .btn:hover {
    text-decoration: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.btn-primary,
input[type=submit] {
  background: #fff;
  color: #000;
  border: 2px solid #000;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  padding: 10px 25px;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  cursor: pointer;
  color: #000; }
  .btn-primary:focus, .btn-primary:hover,
  input[type=submit]:focus,
  input[type=submit]:hover {
    text-decoration: none;
    background: #f7403b;
    color: #fff;
    border: 2px solid #f7403b; }
  @media (min-width: 992px) {
    .btn-primary.btn-big,
    input[type=submit].btn-big {
      padding: 18px 55px; } }

.btn-close {
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid #fff !important;
  color: #fff !important;
  text-shadow: 0;
  opacity: 1;
  font-size: 15px;
  font-size: 0.9375rem;
  -moz-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  -webkit-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms; }
  .btn-close:hover, .btn-close:focus {
    color: #fff !important;
    background-color: #fff; }

.ErrorLabel,
.EditingFormErrorLabel {
  position: relative;
  display: block;
  width: 100%;
  background-color: #f7403b; }

.pt-5 {
  padding-top: 5px; }

.pb-5 {
  padding-bottom: 5px; }

.mt-5 {
  margin-top: 5px; }

.mb-5 {
  margin-bottom: 5px; }

.pt-10 {
  padding-top: 10px; }

.pb-10 {
  padding-bottom: 10px; }

.mt-10 {
  margin-top: 10px; }

.mb-10 {
  margin-bottom: 10px; }

.pt-15 {
  padding-top: 15px; }

.pb-15 {
  padding-bottom: 15px; }

.mt-15 {
  margin-top: 15px; }

.mb-15 {
  margin-bottom: 15px; }

.pt-20 {
  padding-top: 20px; }

.pb-20 {
  padding-bottom: 20px; }

.mt-20 {
  margin-top: 20px; }

.mb-20 {
  margin-bottom: 20px; }

.pt-25 {
  padding-top: 25px; }

.pb-25 {
  padding-bottom: 25px; }

.mt-25 {
  margin-top: 25px; }

.mb-25 {
  margin-bottom: 25px; }

.pt-30 {
  padding-top: 30px; }

.pb-30 {
  padding-bottom: 30px; }

.mt-30 {
  margin-top: 30px; }

.mb-30 {
  margin-bottom: 30px; }

.pt-35 {
  padding-top: 35px; }

.pb-35 {
  padding-bottom: 35px; }

.mt-35 {
  margin-top: 35px; }

.mb-35 {
  margin-bottom: 35px; }

.pt-40 {
  padding-top: 40px; }

.pb-40 {
  padding-bottom: 40px; }

.mt-40 {
  margin-top: 40px; }

.mb-40 {
  margin-bottom: 40px; }

.pt-45 {
  padding-top: 45px; }

.pb-45 {
  padding-bottom: 45px; }

.mt-45 {
  margin-top: 45px; }

.mb-45 {
  margin-bottom: 45px; }

.pt-50 {
  padding-top: 50px; }

.pb-50 {
  padding-bottom: 50px; }

.mt-50 {
  margin-top: 50px; }

.mb-50 {
  margin-bottom: 50px; }

.pt-55 {
  padding-top: 55px; }

.pb-55 {
  padding-bottom: 55px; }

.mt-55 {
  margin-top: 55px; }

.mb-55 {
  margin-bottom: 55px; }

.pt-60 {
  padding-top: 60px; }

.pb-60 {
  padding-bottom: 60px; }

.mt-60 {
  margin-top: 60px; }

.mb-60 {
  margin-bottom: 60px; }

.pt-65 {
  padding-top: 65px; }

.pb-65 {
  padding-bottom: 65px; }

.mt-65 {
  margin-top: 65px; }

.mb-65 {
  margin-bottom: 65px; }

.pt-70 {
  padding-top: 70px; }

.pb-70 {
  padding-bottom: 70px; }

.mt-70 {
  margin-top: 70px; }

.mb-70 {
  margin-bottom: 70px; }

.pt-75 {
  padding-top: 75px; }

.pb-75 {
  padding-bottom: 75px; }

.mt-75 {
  margin-top: 75px; }

.mb-75 {
  margin-bottom: 75px; }

.pt-80 {
  padding-top: 80px; }

.pb-80 {
  padding-bottom: 80px; }

.mt-80 {
  margin-top: 80px; }

.mb-80 {
  margin-bottom: 80px; }

.pt-85 {
  padding-top: 85px; }

.pb-85 {
  padding-bottom: 85px; }

.mt-85 {
  margin-top: 85px; }

.mb-85 {
  margin-bottom: 85px; }

.pt-90 {
  padding-top: 90px; }

.pb-90 {
  padding-bottom: 90px; }

.mt-90 {
  margin-top: 90px; }

.mb-90 {
  margin-bottom: 90px; }

.pt-95 {
  padding-top: 95px; }

.pb-95 {
  padding-bottom: 95px; }

.mt-95 {
  margin-top: 95px; }

.mb-95 {
  margin-bottom: 95px; }

.pt-100 {
  padding-top: 100px; }

.pb-100 {
  padding-bottom: 100px; }

.mt-100 {
  margin-top: 100px; }

.mb-100 {
  margin-bottom: 100px; }

.main-content {
  width: 100%;
  margin-top: 20px; }
  @media (min-width: 768px) {
    .main-content {
      margin-top: 0px; } }
  .main-content .products-pic {
    max-width: 100%;
    margin-bottom: 20px; }
  .main-content .products-text {
    margin-bottom: 20px; }

.products-mobile-menu {
  display: none;
  margin-top: 10px;
  font-size: 16px;
  font-size: 1rem; }
  .products-mobile-menu:hover {
    color: #000 !important;
    background: #fff; }
  .products-mobile-menu:after {
    display: inline-block;
    vertical-align: middle;
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e903";
    margin-left: 5px;
    margin-top: -3px;
    font-size: 35px;
    font-size: 2.1875rem;
    -webkit-transition-property: transform;
    -moz-transition-property: transform;
    -o-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 250ms;
    -moz-transition-duration: 250ms;
    -o-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease; }
  .products-mobile-menu.active:after {
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  @media (min-width: 320px) and (max-width: 767px) {
    .products-mobile-menu {
      display: block; } }

.products-menu {
  width: 100.25%;
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 20px; }
  @media (min-width: 768px) {
    .products-menu {
      display: block !important; } }
  @media (min-width: 992px) {
    .products-menu {
      margin-top: 0px; } }
  .products-menu .products-menu-item > a {
    display: block;
    text-decoration: none;
    font-size: 16px;
    font-size: 1rem;
    line-height: 17px;
    color: #000;
    font-weight: 600;
    padding: 5px 5px;
    -webkit-transition-property: background-color, color;
    -moz-transition-property: background-color, color;
    -o-transition-property: background-color, color;
    transition-property: background-color, color;
    -webkit-transition-duration: 250ms;
    -moz-transition-duration: 250ms;
    -o-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease; }
    @media (min-width: 768px) {
      .products-menu .products-menu-item > a {
        font-size: 14px;
        font-size: 0.875rem; } }
    @media (min-width: 992px) {
      .products-menu .products-menu-item > a {
        font-size: 16px;
        font-size: 1rem;
        font-weight: 800;
        padding: 5px 10px; } }
    @media (min-width: 1200px) {
      .products-menu .products-menu-item > a {
        font-size: 18px;
        font-size: 1.125rem;
        line-height: 20px; } }
    .products-menu .products-menu-item > a:hover, .products-menu .products-menu-item > a:focus {
      background-color: #009641; }
  .products-menu .products-menu-item.active > a {
    background-color: #009641; }
  .products-menu .products-menu-item.active .products-submenu {
    display: block; }
  .products-menu .products-menu-item .products-submenu {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: #e0e0e0;
    padding: 0 10px;
    margin-bottom: 10px;
    text-decoration: none;
    display: none; }
    .products-menu .products-menu-item .products-submenu .products-submenu-item > a {
      position: relative;
      display: block;
      padding: 7px 0 7px 10px;
      color: #000;
      font-size: 16px;
      font-size: 1rem;
      line-height: 17px;
      text-decoration: none; }
      @media (min-width: 768px) {
        .products-menu .products-menu-item .products-submenu .products-submenu-item > a {
          padding: 5px 0 5px 10px;
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media (min-width: 992px) {
        .products-menu .products-menu-item .products-submenu .products-submenu-item > a {
          font-size: 16px;
          font-size: 1rem; } }
      @media (min-width: 1200px) {
        .products-menu .products-menu-item .products-submenu .products-submenu-item > a {
          font-size: 18px;
          font-size: 1.125rem;
          line-height: 20px; } }
      .products-menu .products-menu-item .products-submenu .products-submenu-item > a:before {
        display: block;
        content: "";
        width: 4px;
        height: 4px;
        background: #000;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        border-radius: 10px;
        position: absolute;
        left: 0;
        top: 14px; }
      .products-menu .products-menu-item .products-submenu .products-submenu-item > a:hover, .products-menu .products-menu-item .products-submenu .products-submenu-item > a:focus {
        text-decoration: underline; }
    .products-menu .products-menu-item .products-submenu .products-submenu-item .active {
      font-weight: 800; }

.products-list .products-item-card,
.content-list .products-item-card {
  display: block;
  width: 100%;
  margin-top: 10px;
  background-color: #e0e0e0;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  color: #000;
  text-decoration: none; }
  @media (min-width: 545px) {
    .products-list .products-item-card,
    .content-list .products-item-card {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-flex-flow: row nowrap;
      -ms-flex-direction: row;
      -ms-flex-wrap: nowrap;
      -webkit-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: space-between;
      -moz-box-pack: space-between;
      -ms-flex-pack: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      height: 140px; } }
  @media (min-width: 545px) and (max-width: 767px) {
    .products-list .products-item-card,
    .content-list .products-item-card {
      border: 7px solid #e0e0e0;
      height: 160px; } }
  @media (min-width: 545px) and (max-width: 650px) {
    .products-list .products-item-card,
    .content-list .products-item-card {
      border: 1px solid #e0e0e0;
      height: 140px; } }
  @media (min-width: 992px) {
    .products-list .products-item-card,
    .content-list .products-item-card {
      border: 7px solid #e0e0e0;
      height: 158px; } }
  .products-list .products-item-card:hover, .products-list .products-item-card:focus,
  .content-list .products-item-card:hover,
  .content-list .products-item-card:focus {
    background-color: #009641;
    border-color: #009641;
    text-decoration: none !important; }
    .products-list .products-item-card:hover .products-item-card-content:before, .products-list .products-item-card:focus .products-item-card-content:before,
    .content-list .products-item-card:hover .products-item-card-content:before,
    .content-list .products-item-card:focus .products-item-card-content:before {
      background-color: #000; }
    .products-list .products-item-card:hover .products-item-card-content:after, .products-list .products-item-card:focus .products-item-card-content:after,
    .content-list .products-item-card:hover .products-item-card-content:after,
    .content-list .products-item-card:focus .products-item-card-content:after {
      color: #009641; }
  .products-list .products-item-card-img,
  .content-list .products-item-card-img {
    width: 100%;
    height: 0;
    padding-bottom: 80%;
    background-color: #fff;
    overflow: hidden;
    position: relative; }
    @media (min-width: 545px) {
      .products-list .products-item-card-img,
      .content-list .products-item-card-img {
        width: 48.5%;
        height: 100%;
        padding-bottom: 0; } }
    .products-list .products-item-card-img img,
    .content-list .products-item-card-img img {
      display: block;
      position: absolute;
      position: absolute;
      left: -200%;
      right: -200%;
      margin: auto !important;
      bottom: -200%;
      top: -200%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover; }
  .products-list .products-item-card-content,
  .content-list .products-item-card-content {
    width: 100%;
    position: relative; }
    @media (min-width: 545px) {
      .products-list .products-item-card-content,
      .content-list .products-item-card-content {
        width: 48.5%;
        padding: 5px 10px 10px 0; } }
    .products-list .products-item-card-content .products-item-title,
    .content-list .products-item-card-content .products-item-title {
      font-size: 15px;
      line-height: 26px;
      max-height: 26px;
      overflow: hidden;
      font-weight: 600; }
      @media (min-width: 320px) and (max-width: 544px) {
        .products-list .products-item-card-content .products-item-title,
        .content-list .products-item-card-content .products-item-title {
          text-decoration: underline;
          margin: 0;
          padding: 0 5px; } }
      @media (min-width: 545px) {
        .products-list .products-item-card-content .products-item-title,
        .content-list .products-item-card-content .products-item-title {
          max-height: 52px;
          line-height: 26px;
          font-size: 18px;
          margin-bottom: 5px;
          background-image: linear-gradient(#000 2px, transparent 2px);
          background-size: 100% 26px;
          background-position: 0 23px; } }
      @media (min-width: 768px) and (max-width: 991px) {
        .products-list .products-item-card-content .products-item-title,
        .content-list .products-item-card-content .products-item-title {
          font-size: 15px;
          line-height: 20px;
          max-height: 40px;
          background-size: 100% 20px;
          background-position: 0 18px; } }
      @media (min-width: 992px) {
        .products-list .products-item-card-content .products-item-title,
        .content-list .products-item-card-content .products-item-title {
          max-height: 52px;
          font-size: 18px;
          line-height: 26px;
          font-weight: 800; } }
      .products-list .products-item-card-content .products-item-title a,
      .content-list .products-item-card-content .products-item-title a {
        display: block;
        color: #000;
        text-decoration: none; }
    .products-list .products-item-card-content .products-item-perex,
    .content-list .products-item-card-content .products-item-perex {
      display: block;
      height: 36px;
      line-height: 18px;
      overflow: hidden;
      display: none; }
      @media (min-width: 545px) {
        .products-list .products-item-card-content .products-item-perex,
        .content-list .products-item-card-content .products-item-perex {
          display: block; } }
    .products-list .products-item-card-content:before,
    .content-list .products-item-card-content:before {
      content: "";
      position: absolute;
      display: none;
      bottom: 13px;
      left: 2px;
      width: 27px;
      height: 27px;
      -webkit-border-radius: 27px;
      -moz-border-radius: 27px;
      -ms-border-radius: 27px;
      border-radius: 27px;
      background-color: transparent;
      -moz-transition: all ease-in-out 100ms;
      -o-transition: all ease-in-out 100ms;
      -webkit-transition: all ease-in-out 100ms;
      transition: all ease-in-out 100ms; }
      @media (min-width: 992px) {
        .products-list .products-item-card-content:before,
        .content-list .products-item-card-content:before {
          display: block; } }
    .products-list .products-item-card-content:after,
    .content-list .products-item-card-content:after {
      content: "\e908";
      position: absolute;
      display: none;
      font-size: 30px;
      font-size: 1.875rem;
      -moz-transition: all ease-in-out 100ms;
      -o-transition: all ease-in-out 100ms;
      -webkit-transition: all ease-in-out 100ms;
      transition: all ease-in-out 100ms; }
      @media (min-width: 545px) {
        .products-list .products-item-card-content:after,
        .content-list .products-item-card-content:after {
          display: block;
          bottom: 0px;
          left: -2px; } }
      @media (min-width: 545px) and (max-width: 991px) {
        .products-list .products-item-card-content:after,
        .content-list .products-item-card-content:after {
          color: #000 !important; } }
      @media (min-width: 992px) {
        .products-list .products-item-card-content:after,
        .content-list .products-item-card-content:after {
          bottom: 12px;
          left: 0px; } }

.button-show-products {
  margin-top: 20px;
  margin-bottom: 30px; }

.main-content-product {
  margin-top: 30px;
  font-size: 14px;
  font-size: 0.875rem; }
  @media (min-width: 768px) {
    .main-content-product {
      margin-top: 0;
      font-size: 16px;
      font-size: 1rem; } }
  .main-content-product .title-line {
    border-bottom: 0px; }
  .main-content-product h2 {
    border-bottom: 2px solid #000;
    margin-top: 30px;
    padding-bottom: 5px;
    margin-bottom: 12px; }
    @media (min-width: 768px) {
      .main-content-product h2 {
        margin-bottom: 16px;
        padding-bottom: 8px;
        font-size: 28px;
        font-size: 1.75rem; } }
  .main-content-product big,
  .main-content-product h3 {
    display: inline-block;
    font-size: 18px;
    font-size: 1.125rem;
    margin-bottom: 3px; }
    @media (min-width: 768px) {
      .main-content-product big,
      .main-content-product h3 {
        font-size: 22px;
        font-size: 1.375rem;
        margin-bottom: 7px; } }
  .main-content-product h3 {
    font-weight: 600; }

.text-info-text .row > div:not(:first-of-type) {
  margin-top: 20px; }
  @media (min-width: 992px) {
    .text-info-text .row > div:not(:first-of-type) {
      margin-top: 0px; } }

@media (min-width: 480px) {
  .product-preview {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-flow: row nowrap;
    -ms-flex-direction: row;
    -ms-flex-wrap: nowrap;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between; } }

.product-preview-image {
  width: 100%; }
  @media (min-width: 480px) {
    .product-preview-image {
      width: 40%; } }
  @media (min-width: 768px) {
    .product-preview-image {
      width: 50%; } }
  @media (min-width: 992px) {
    .product-preview-image {
      width: 50%; } }
  .product-preview-image .img-cont {
    padding: 5px;
    position: relative; }
    .product-preview-image .img-cont img {
      max-width: 100%;
      max-height: 100%; }
      .product-preview-image .img-cont img:not(:first-of-type) {
        display: none; }
    .product-preview-image .img-cont .box-product-social {
      position: absolute;
      top: 7px;
      left: 5px; }
      .product-preview-image .img-cont .box-product-social .product-social-list {
        position: absolute;
        top: 40px;
        left: -5px;
        width: 60px;
        height: 200px;
        opacity: 0;
        background-color: transparent;
        overflow: hidden;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms;
        display: none;
        -moz-flex-flow: column wrap;
        -ms-flex-direction: column;
        -ms-flex-wrap: wrap;
        -webkit-flex-flow: column wrap;
        flex-flow: column wrap; }
        @media (min-width: 480px) {
          .product-preview-image .img-cont .box-product-social .product-social-list {
            top: 35px;
            width: 80px;
            height: 140px; } }
        @media (min-width: 768px) {
          .product-preview-image .img-cont .box-product-social .product-social-list {
            width: 80px;
            height: 150px; } }
        @media (min-width: 992px) {
          .product-preview-image .img-cont .box-product-social .product-social-list {
            height: 200px; } }
        @media (min-width: 1400px) {
          .product-preview-image .img-cont .box-product-social .product-social-list {
            height: 300px; } }
        .product-preview-image .img-cont .box-product-social .product-social-list.open {
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          opacity: 1;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex; }
        .product-preview-image .img-cont .box-product-social .product-social-list-icon {
          display: block;
          width: 30px;
          height: 30px;
          font-size: 0px;
          font-size: 0rem;
          text-decoration: none;
          position: relative; }
          @media (min-width: 545px) {
            .product-preview-image .img-cont .box-product-social .product-social-list-icon {
              width: 37px;
              height: 37px; } }
          .product-preview-image .img-cont .box-product-social .product-social-list-icon:before {
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 23px;
            font-size: 1.4375rem;
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            color: #009641;
            cursor: pointer; }
            @media (min-width: 545px) {
              .product-preview-image .img-cont .box-product-social .product-social-list-icon:before {
                font-size: 27px;
                font-size: 1.6875rem; } }
          .product-preview-image .img-cont .box-product-social .product-social-list-icon:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            color: #009641;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms;
            cursor: pointer;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 1px solid transparent; }
            @media (min-width: 545px) {
              .product-preview-image .img-cont .box-product-social .product-social-list-icon:after {
                width: 35px;
                height: 35px; } }
          .product-preview-image .img-cont .box-product-social .product-social-list-icon:focus:after, .product-preview-image .img-cont .box-product-social .product-social-list-icon:hover:after {
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms;
            border-color: #009641; }
        .product-preview-image .img-cont .box-product-social .product-social-list .s-google:before {
          content: ""; }
        .product-preview-image .img-cont .box-product-social .product-social-list .s-tumblr:before {
          content: ""; }
        .product-preview-image .img-cont .box-product-social .product-social-list .s-facebook:before {
          content: ""; }
        .product-preview-image .img-cont .box-product-social .product-social-list .s-mail:before {
          content: ""; }
        .product-preview-image .img-cont .box-product-social .product-social-list .s-twitter:before {
          content: ""; }
        .product-preview-image .img-cont .box-product-social .product-social-list .s-linkedin:before {
          content: ""; }
        .product-preview-image .img-cont .box-product-social .product-social-list .s-vine:before {
          content: ""; }
        .product-preview-image .img-cont .box-product-social .product-social-list .s-youtube:before {
          content: ""; }
      .product-preview-image .img-cont .box-product-social .box-product-social-button {
        position: relative;
        display: inline-block;
        color: inherit;
        font-size: 0px;
        font-size: 0rem;
        width: 30px;
        height: 30px;
        color: #000;
        cursor: pointer; }
        .product-preview-image .img-cont .box-product-social .box-product-social-button:before {
          font-family: 'icomoon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 22px;
          font-size: 1.375rem;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          cursor: pointer;
          content: "\e904"; }
        .product-preview-image .img-cont .box-product-social .box-product-social-button:after {
          content: "";
          position: absolute;
          top: 38%;
          left: 42%;
          -ms-transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: #000;
          cursor: pointer;
          width: 30px;
          height: 30px;
          border-radius: 50%; }
          @media (min-width: 545px) {
            .product-preview-image .img-cont .box-product-social .box-product-social-button:after {
              width: 35px;
              height: 35px; } }
        .product-preview-image .img-cont .box-product-social .box-product-social-button:focus:before, .product-preview-image .img-cont .box-product-social .box-product-social-button:hover:before {
          color: #f7403b; }

.product-preview-contact {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-flow: column nowrap;
  -ms-flex-direction: column;
  -ms-flex-wrap: nowrap;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap; }
  @media (min-width: 480px) {
    .product-preview-contact {
      width: -webkit-calc(60% - 20px);
      width: -moz-calc(60% - 20px);
      width: calc(60% - 20px);
      margin-top: 0px; } }
  @media (min-width: 480px) {
    .product-preview-contact {
      display: block; } }
  @media (min-width: 768px) {
    .product-preview-contact {
      width: -webkit-calc(50% - 20px);
      width: -moz-calc(50% - 20px);
      width: calc(50% - 20px); } }
  @media (min-width: 992px) {
    .product-preview-contact {
      width: -webkit-calc(50% - 35px);
      width: -moz-calc(50% - 35px);
      width: calc(50% - 35px); } }
  .product-preview-contact-perex {
    order: 2;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.25;
    margin-top: 20px;
    font-weight: 600; }
    @media (min-width: 480px) {
      .product-preview-contact-perex {
        margin-top: 0px; } }
    @media (min-width: 992px) {
      .product-preview-contact-perex {
        font-size: 18px;
        font-size: 1.125rem; } }
  .product-preview-contact-types {
    display: none; }
  .product-preview-contact-load {
    display: none; }
  .product-preview-contact-colors {
    display: none; }

.product-preview .product-contact {
  margin-top: 20px; }
  .product-preview .product-contact .contact-box-button {
    width: -webkit-calc(100% - 2px);
    width: -moz-calc(100% - 2px);
    width: calc(100% - 2px);
    position: absolute;
    bottom: 12px;
    right: 10px;
    width: auto;
    font-size: 13px;
    font-size: 0.8125rem; }
    @media (min-width: 480px) {
      .product-preview .product-contact .contact-box-button {
        position: static;
        width: auto;
        min-width: 128px;
        font-size: 14px;
        font-size: 0.875rem; } }
    @media (min-width: 992px) {
      .product-preview .product-contact .contact-box-button {
        min-width: 123px;
        position: absolute; } }
    @media (min-width: 992px) and (max-width: 1024px) {
      .product-preview .product-contact .contact-box-button {
        min-width: 100px;
        width: 110px; } }
    @media (min-width: 1200px) {
      .product-preview .product-contact .contact-box-button {
        min-width: 155px;
        position: absolute; } }

.product-technical-info {
  padding: 5px 0 25px;
  margin-top: 15px; }
  .product-technical-info-item {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-flow: row nowrap;
    -ms-flex-direction: row;
    -ms-flex-wrap: nowrap;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
    border-bottom: 1px solid #000;
    margin-bottom: 5px; }
    .product-technical-info-item-name, .product-technical-info-item-value {
      width: 50%;
      background-color: #e0e0e0;
      padding: 3px 5px;
      font-size: 13px;
      font-size: 0.8125rem; }
      @media (min-width: 768px) {
        .product-technical-info-item-name, .product-technical-info-item-value {
          padding: 3px 10px;
          font-size: 14px;
          font-size: 0.875rem; } }
    .product-technical-info-item-value {
      background-color: #fff;
      text-align: right;
      line-height: 18px; }

.product-table {
  width: 100%;
  display: block;
  overflow-x: auto;
  margin-top: 15px; }
  @media (min-width: 545px) {
    .product-table {
      display: table;
      overflow-x: hidden; } }
  .product-table-title {
    background-color: #ffd100;
    font-weight: 600; }
  .product-table tr:nth-child(2) td {
    font-weight: 600; }
  .product-table tr td:first-child {
    font-weight: 600;
    border-right: 1px solid #000;
    padding: 2px 3px; }
  .product-table-title td {
    border-right: none !important; }
  .product-table td {
    border: none;
    border-right: 1px solid #dedede;
    border-bottom: 1px solid #000; }
  .product-table tr td {
    padding: 2px 10px;
    border: 1px solid #dedede; }
    @media (min-width: 545px) {
      .product-table tr td {
        padding: 2px 3px; } }

.main-slider {
  width: 100%;
  position: relative; }
  .main-slider-item {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
    background-position: top center !important;
    background-repeat: no-repeat !important;
    -webkit-background-size: cover !important;
    background-size: cover !important; }
    .main-slider-item-title {
      font-size: 35px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      padding: 0 15px;
      color: #fff;
      font-family: 'Druk-Wide'; }
      @media (min-width: 545px) {
        .main-slider-item-title {
          font-size: 40px;
          line-height: 45px; } }
      @media (min-width: 768px) {
        .main-slider-item-title {
          font-size: 55px;
          line-height: 60px; } }
      @media (min-width: 992px) {
        .main-slider-item-title {
          font-size: 65px;
          line-height: 70px;
          text-align: left;
          padding: 30px 15px;
          max-width: 40%; } }
      @media (min-width: 1200px) {
        .main-slider-item-title {
          font-size: 70px;
          line-height: 75px; } }
      @media (min-width: 1400px) {
        .main-slider-item-title {
          font-size: 92px;
          line-height: 100px;
          padding: 30px 50px; } }
    .main-slider-item-title-bottom {
      margin-top: 30px;
      text-align: center;
      padding: 0 15px;
      font-size: 26px;
      font-size: 1.625rem;
      line-height: 32px;
      color: #fff; }
      @media (min-width: 768px) {
        .main-slider-item-title-bottom {
          margin-top: 50px; } }
      @media (min-width: 992px) {
        .main-slider-item-title-bottom {
          text-align: left;
          padding: 30px 15px;
          max-width: 40%;
          font-size: 36px;
          font-size: 2.25rem;
          line-height: 43px; } }
      @media (min-width: 1400px) {
        .main-slider-item-title-bottom {
          padding: 30px 50px; } }
    .main-slider-item-inner {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      min-height: 350px;
      -moz-flex-flow: column nowrap;
      -ms-flex-direction: column;
      -ms-flex-wrap: nowrap;
      -webkit-flex-flow: column nowrap;
      flex-flow: column nowrap;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      padding: 40px 0; }
      @media (min-width: 545px) {
        .main-slider-item-inner {
          min-height: 400px; } }
      @media (min-width: 768px) {
        .main-slider-item-inner {
          height: 500px; } }
      @media (min-width: 992px) {
        .main-slider-item-inner {
          height: 715px;
          -moz-flex-flow: column nowrap;
          -ms-flex-direction: column;
          -ms-flex-wrap: nowrap;
          -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
          -webkit-box-pack: space-between;
          -moz-box-pack: space-between;
          -ms-flex-pack: space-between;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          -webkit-box-align: flex-start;
          -moz-box-align: flex-start;
          -ms-flex-align: flex-start;
          -webkit-align-items: flex-start;
          align-items: flex-start;
          padding: 0; } }
  .main-slider .slick-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15px;
    text-align: center;
    padding: 0;
    margin: 0; }
    .main-slider .slick-dots li {
      display: inline-block;
      vertical-align: middle;
      margin: 0 4px;
      width: 15px;
      height: 15px;
      cursor: pointer;
      background: transparent;
      border: 3px solid #fff;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      -webkit-transition-property: all;
      -moz-transition-property: all;
      -o-transition-property: all;
      transition-property: all;
      -webkit-transition-duration: 250ms;
      -moz-transition-duration: 250ms;
      -o-transition-duration: 250ms;
      transition-duration: 250ms;
      -webkit-transition-timing-function: ease;
      -moz-transition-timing-function: ease;
      -o-transition-timing-function: ease;
      transition-timing-function: ease; }
      .main-slider .slick-dots li:hover {
        background: #000; }
      .main-slider .slick-dots li button {
        display: none; }
      .main-slider .slick-dots li.slick-active {
        background-color: #fff; }

.hp-card-container {
  margin-top: 30px; }
  @media (min-width: 768px) {
    .hp-card-container {
      margin-top: 40px; } }
  .hp-card-container h2 {
    margin-bottom: 0;
    margin-right: 20px; }
  .hp-card-container .hp-card-slider {
    overflow: hidden; }
  .hp-card-container .slick-list {
    margin-left: -15px;
    margin-right: -15px; }
    @media (min-width: 992px) {
      .hp-card-container .slick-list {
        margin-left: -20px;
        margin-right: -20px; } }
    .hp-card-container .slick-list .slick-slide {
      padding-left: 15px;
      padding-right: 15px;
      overflow: hidden; }
      @media (min-width: 992px) {
        .hp-card-container .slick-list .slick-slide {
          padding-left: 20px;
          padding-right: 20px; } }
  .hp-card-container .slick-arrow {
    position: absolute;
    top: 50%;
    width: 20px;
    height: 40px;
    transform: translateY(-50%);
    font-size: 0px;
    font-size: 0rem;
    cursor: pointer;
    z-index: 99;
    border: 0;
    background: transparent; }
    .hp-card-container .slick-arrow:before {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e920";
      position: relative;
      display: inline-block;
      color: rgba(0, 0, 0, 0.4);
      font-weight: 600;
      font-size: 40px;
      font-size: 2.5rem;
      width: 20px;
      height: 40px;
      border-radius: 40px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; }
    .hp-card-container .slick-arrow:focus, .hp-card-container .slick-arrow:hover {
      outline: 0;
      border: 0; }
      .hp-card-container .slick-arrow:focus:before, .hp-card-container .slick-arrow:hover:before {
        color: black; }
  .hp-card-container .slick-prev {
    left: 0; }
  .hp-card-container .slick-next {
    right: 13px; }
    .hp-card-container .slick-next:before {
      content: "\e91f"; }
  .hp-card-container .slick-disabled:before {
    opacity: 0; }

.hp-card-title-box {
  margin-bottom: 30px; }
  @media (min-width: 768px) {
    .hp-card-title-box {
      margin-bottom: 40px; } }

.news-items .slick-arrow {
  top: 20%; }
  @media (min-width: 768px) {
    .news-items .slick-arrow {
      top: 40%; } }
  @media (min-width: 992px) {
    .news-items .slick-arrow {
      top: 50%; } }

.devider-title-link {
  text-decoration: none;
  color: #000;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  font-size: 16px;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; }
  .devider-title-link:after {
    content: "\e908";
    background: transparent;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid #000;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    width: 30px;
    height: 30px;
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 29px; }
  .devider-title-link:focus, .devider-title-link:hover {
    color: #000; }
    .devider-title-link:focus:after, .devider-title-link:hover:after {
      background: #f7403b;
      color: #fff;
      border: 1px solid #f7403b; }
  .devider-title-link span {
    display: none; }
  @media (min-width: 480px) {
    .devider-title-link span {
      display: inline-block;
      margin: 0 0 0 5px; }
    .devider-title-link:after {
      margin: 2px 0 0 10px; } }
  @media (min-width: 768px) {
    .devider-title-link {
      font-size: 20px; }
      .devider-title-link h2 {
        font-size: 27px;
        line-height: 30px; }
      .devider-title-link:after {
        width: 30px;
        height: 30px;
        font-size: 30px;
        font-size: 1.875rem;
        line-height: 29px; } }
  @media (min-width: 992px) {
    .devider-title-link {
      padding: 15px 0; }
      .devider-title-link h2 {
        font-size: 30px;
        line-height: 34px; } }
  @media (min-width: 1200px) {
    .devider-title-link h2 {
      font-size: 35px; }
    .devider-title-link:after {
      width: 34px;
      height: 34px;
      font-size: 34px;
      font-size: 2.125rem;
      line-height: 33px;
      position: relative;
      top: 2px; } }
  @media (min-width: 1400px) {
    .devider-title-link {
      padding: 20px 0;
      font-size: 22px;
      line-height: 1;
      color: #000; }
      .devider-title-link h2 {
        font-size: 41px;
        line-height: 1; } }

.hp-project-item {
  height: 350px !important;
  background-position: center !important;
  background-size: cover !important;
  width: 100%;
  display: flex !important;
  -moz-flex-flow: column nowrap;
  -ms-flex-direction: column;
  -ms-flex-wrap: nowrap;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: flex-end;
  -moz-box-pack: flex-end;
  -ms-flex-pack: flex-end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end; }
  @media (min-width: 768px) {
    .hp-project-item {
      height: 400px !important; } }
  @media (min-width: 992px) {
    .hp-project-item {
      height: 440px !important; } }
  .hp-project-item .h2 {
    color: #fff;
    padding: 0 0 10px 20px;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    @media (min-width: 992px) {
      .hp-project-item .h2 {
        padding: 0 0 20px 30px; } }
  .hp-project-item:hover .h2, .hp-project-item:focus .h2 {
    color: #f7403b; }

.search-form {
  position: relative;
  margin-top: 30px; }
  @media (min-width: 768px) {
    .search-form {
      margin-top: 0; } }
  .search-form-input {
    width: 100%;
    height: 40px;
    padding-left: 10px;
    padding-right: 45px;
    background-color: #e0e0e0;
    outline: 0;
    border: 0px;
    -webkit-transition-property: background-color, color;
    -moz-transition-property: background-color, color;
    -o-transition-property: background-color, color;
    transition-property: background-color, color;
    -webkit-transition-duration: 250ms;
    -moz-transition-duration: 250ms;
    -o-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    font-size: 18px;
    font-size: 1.125rem;
    border-bottom: 2px solid #000; }
    @media (min-width: 992px) {
      .search-form-input {
        height: 50px;
        padding-right: 75px;
        font-size: 22px;
        font-size: 1.375rem; } }
    .search-form-input:focus {
      outline: 0; }
  .search-form-button {
    position: absolute;
    top: 50%;
    right: 10px;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    display: inline-block;
    width: 30px;
    height: 30px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid #000;
    cursor: pointer;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    @media (min-width: 992px) {
      .search-form-button {
        right: 10px;
        width: 36px;
        height: 36px; } }
    .search-form-button:focus, .search-form-button:hover {
      background-color: #f7403b;
      border: 1px solid #f7403b; }
      .search-form-button:focus:after, .search-form-button:hover:after {
        color: #fff; }
    .search-form-button:after {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e909";
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-size: 18px;
      font-size: 1.125rem;
      color: #000; }
      @media (min-width: 992px) {
        .search-form-button:after {
          font-size: 22px;
          font-size: 1.375rem; } }
    .search-form-button.cancel:after {
      content: "\e921";
      font-size: 15px;
      font-size: 0.9375rem; }
      @media (min-width: 992px) {
        .search-form-button.cancel:after {
          font-size: 18px;
          font-size: 1.125rem; } }

.search-list .full-width-item {
  text-decoration: none !important;
  color: #000 !important; }

.products-list .items-container {
  margin-left: -5px;
  margin-right: -5px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.small-space .items-container [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px; }

.big-space > .row,
.big-space > .items-container {
  margin-left: -15px;
  margin-right: -15px; }
  .big-space > .row > .col-md-3,
  .big-space > .row > .col-md-9,
  .big-space > .items-container > .col-md-3,
  .big-space > .items-container > .col-md-9 {
    padding-left: 15px;
    padding-right: 15px; }
    @media (min-width: 992px) {
      .big-space > .row > .col-md-3,
      .big-space > .row > .col-md-9,
      .big-space > .items-container > .col-md-3,
      .big-space > .items-container > .col-md-9 {
        padding-left: 13px;
        padding-right: 13px; } }
    @media (min-width: 1200px) {
      .big-space > .row > .col-md-3,
      .big-space > .row > .col-md-9,
      .big-space > .items-container > .col-md-3,
      .big-space > .items-container > .col-md-9 {
        padding-left: 26px;
        padding-right: 26px; }
        .big-space > .row > .col-md-3:last-of-type,
        .big-space > .row > .col-md-9:last-of-type,
        .big-space > .items-container > .col-md-3:last-of-type,
        .big-space > .items-container > .col-md-9:last-of-type {
          padding-left: 33.5px; } }

.small-space .row,
.small-space .items-container {
  margin-left: -5px;
  margin-right: -5px; }
  .small-space .row [class*="col-"],
  .small-space .items-container [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px; }

@media (min-width: 992px) {
  .space-large .row,
  .space-large .items-container,
  .space-30 .row,
  .space-30 .items-container {
    margin-left: -15px;
    margin-right: -15px; } }

@media (min-width: 992px) {
  .space-large .row [class*="col-"],
  .space-large .items-container [class*="col-"],
  .space-30 .row [class*="col-"],
  .space-30 .items-container [class*="col-"] {
    padding-left: 30px;
    padding-right: 30px; } }

@media (min-width: 1400px) {
  .space-20 .row,
  .space-20 .items-container {
    margin-left: -20px;
    margin-right: -20px; } }

@media (min-width: 1400px) {
  .space-20 .row [class*="col-"],
  .space-20 .items-container [class*="col-"] {
    padding-left: 20px;
    padding-right: 20px; } }

.items-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -ms-flex-direction: row;
  -ms-flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px; }

.box-header {
  position: relative;
  max-width: 1290px;
  min-height: 90px;
  margin: 0 auto;
  margin-bottom: 10px;
  background-color: #009641;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  color: #fff; }
  @media (min-width: 992px) {
    .box-header {
      min-height: 125px;
      margin-bottom: 35px; } }
  .box-header .box-header-content {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 15px 0 10px; }
    .box-header .box-header-content .pux-container {
      position: relative;
      padding-right: 80px !important; }
      @media (min-width: 1200px) {
        .box-header .box-header-content .pux-container {
          padding: 0 50px; } }
    @media (min-width: 480px) {
      .box-header .box-header-content {
        padding: 25px 0 15px; } }
    @media (min-width: 992px) {
      .box-header .box-header-content {
        padding: 25px 0 20px; } }
    .box-header .box-header-content .breadcrumbs {
      color: inherit;
      line-height: 18px;
      padding-right: 80px; }
      .box-header .box-header-content .breadcrumbs a,
      .box-header .box-header-content .breadcrumbs span {
        margin-top: -5px; }
      .box-header .box-header-content .breadcrumbs .CMSBreadCrumbsCurrentItem {
        color: inherit; }
      .box-header .box-header-content .breadcrumbs a {
        color: inherit;
        text-decoration: none; }
        .box-header .box-header-content .breadcrumbs a:hover, .box-header .box-header-content .breadcrumbs a:focus {
          text-decoration: underline; }
    .box-header .box-header-content .box-header-title {
      padding-top: 30px;
      font-weight: bold;
      margin-bottom: 0; }
      @media (min-width: 992px) {
        .box-header .box-header-content .box-header-title {
          padding-top: 40px; } }
      .box-header .box-header-content .box-header-title.h1 {
        font-family: 'Druk-Wide';
        color: #fff;
        font-weight: 600;
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 32px;
        line-height: 2rem;
        line-height: 36px; }
        @media (min-width: 768px) {
          .box-header .box-header-content .box-header-title.h1 {
            font-size: 32px;
            font-size: 2rem;
            line-height: 36px;
            line-height: 2.25rem;
            line-height: 38px; } }
        @media (min-width: 992px) {
          .box-header .box-header-content .box-header-title.h1 {
            font-size: 36px;
            font-size: 2.25rem;
            line-height: 40px;
            line-height: 2.5rem;
            line-height: 43px; } }
    .box-header .box-header-content .box-header-social-print {
      position: absolute;
      top: -5px;
      right: 15px; }
      @media (min-width: 768px) {
        .box-header .box-header-content .box-header-social-print {
          top: -15px; } }
      @media (min-width: 1200px) {
        .box-header .box-header-content .box-header-social-print {
          right: 50px; } }
      .box-header .box-header-content .box-header-social-print .social-list {
        position: absolute;
        top: -5px;
        right: 65px;
        width: auto;
        height: 30px;
        background-color: #000;
        overflow: hidden;
        -moz-transition: all ease-in-out 100ms;
        -o-transition: all ease-in-out 100ms;
        -webkit-transition: all ease-in-out 100ms;
        transition: all ease-in-out 100ms;
        display: none;
        opacity: 0; }
        @media (min-width: 545px) {
          .box-header .box-header-content .box-header-social-print .social-list {
            height: 37px;
            top: -2px;
            right: 80px; } }
        .box-header .box-header-content .box-header-social-print .social-list.open {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          opacity: 1; }
        .box-header .box-header-content .box-header-social-print .social-list-icon {
          display: inline-block;
          float: left;
          width: 30px;
          height: 30px;
          font-size: 0px;
          font-size: 0rem;
          text-decoration: none;
          position: relative; }
          @media (min-width: 768px) {
            .box-header .box-header-content .box-header-social-print .social-list-icon {
              width: 37px;
              height: 37px; } }
          .box-header .box-header-content .box-header-social-print .social-list-icon:before {
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 23px;
            font-size: 1.4375rem;
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            color: #fff;
            -moz-transition: all ease-in-out 100ms;
            -o-transition: all ease-in-out 100ms;
            -webkit-transition: all ease-in-out 100ms;
            transition: all ease-in-out 100ms;
            cursor: pointer; }
            @media (min-width: 545px) {
              .box-header .box-header-content .box-header-social-print .social-list-icon:before {
                font-size: 27px;
                font-size: 1.6875rem; } }
          .box-header .box-header-content .box-header-social-print .social-list-icon:focus:before, .box-header .box-header-content .box-header-social-print .social-list-icon:hover:before {
            -moz-transition: all ease-in-out 100ms;
            -o-transition: all ease-in-out 100ms;
            -webkit-transition: all ease-in-out 100ms;
            transition: all ease-in-out 100ms;
            color: #f7403b; }
        .box-header .box-header-content .box-header-social-print .social-list .s-google:before {
          content: ""; }
        .box-header .box-header-content .box-header-social-print .social-list .s-tumblr:before {
          content: ""; }
        .box-header .box-header-content .box-header-social-print .social-list .s-facebook:before {
          content: ""; }
        .box-header .box-header-content .box-header-social-print .social-list .s-mail:before {
          content: ""; }
        .box-header .box-header-content .box-header-social-print .social-list .s-twitter:before {
          content: ""; }
        .box-header .box-header-content .box-header-social-print .social-list .s-linkedin:before {
          content: ""; }
        .box-header .box-header-content .box-header-social-print .social-list .s-vine:before {
          content: ""; }
        .box-header .box-header-content .box-header-social-print .social-list .s-youtube:before {
          content: ""; }
      .box-header .box-header-content .box-header-social-print .box-header-social,
      .box-header .box-header-content .box-header-social-print .box-header-print {
        display: inline-block;
        color: inherit;
        font-size: 0px;
        font-size: 0rem;
        width: 25px;
        height: 25px; }
        @media (min-width: 545px) {
          .box-header .box-header-content .box-header-social-print .box-header-social,
          .box-header .box-header-content .box-header-social-print .box-header-print {
            width: 35px;
            height: 35px; } }
        .box-header .box-header-content .box-header-social-print .box-header-social:after,
        .box-header .box-header-content .box-header-social-print .box-header-print:after {
          font-family: 'icomoon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 20px;
          font-size: 1.25rem;
          -moz-transition: all ease-in-out 100ms;
          -o-transition: all ease-in-out 100ms;
          -webkit-transition: all ease-in-out 100ms;
          transition: all ease-in-out 100ms;
          cursor: pointer; }
          @media (min-width: 545px) {
            .box-header .box-header-content .box-header-social-print .box-header-social:after,
            .box-header .box-header-content .box-header-social-print .box-header-print:after {
              font-size: 33px;
              font-size: 2.0625rem; } }
        .box-header .box-header-content .box-header-social-print .box-header-social:focus:after, .box-header .box-header-content .box-header-social-print .box-header-social:hover:after,
        .box-header .box-header-content .box-header-social-print .box-header-print:focus:after,
        .box-header .box-header-content .box-header-social-print .box-header-print:hover:after {
          -moz-transition: all ease-in-out 100ms;
          -o-transition: all ease-in-out 100ms;
          -webkit-transition: all ease-in-out 100ms;
          transition: all ease-in-out 100ms;
          color: #f7403b; }
      .box-header .box-header-content .box-header-social-print .box-header-social:after {
        content: "\e904"; }
      .box-header .box-header-content .box-header-social-print .box-header-print:after {
        content: "\e902"; }

.main-content {
  width: 100%;
  margin-top: 20px; }
  @media (min-width: 768px) {
    .main-content {
      margin-top: 0px; } }
  .main-content h1,
  .main-content h2 {
    padding-bottom: 5px;
    border-bottom: 2px solid #000;
    margin-bottom: 15px; }
  .main-content .insert-image {
    max-width: 100%; }
    .main-content .insert-image img {
      max-width: 100%; }
  .main-content .content-text {
    margin-bottom: 20px; }
  .main-content a {
    text-decoration: none;
    color: #000; }
    .main-content a:hover, .main-content a:focus {
      text-decoration: underline; }

.product-video-holder {
  position: relative;
  margin-bottom: 20px;
  margin-top: 15px; }
  @media (min-width: 768px) {
    .product-video-holder {
      margin-top: 0;
      margin-bottom: 30px; } }
  .product-video-holder:before {
    content: "";
    display: block;
    padding-bottom: 56.3%; }
  .product-video-holder iframe {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

.product-video-text {
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .product-video-text {
      margin-bottom: 30px; } }

.content-button-level-up {
  position: relative;
  display: inline-block;
  padding-left: 35px;
  margin-top: 15px;
  margin-bottom: 25px;
  line-height: 20px;
  font-size: 14px;
  font-size: 0.875rem; }
  @media (min-width: 992px) {
    .content-button-level-up {
      padding-left: 45px;
      font-size: 18px;
      font-size: 1.125rem;
      font-weight: 600; } }
  .content-button-level-up:hover, .content-button-level-up:focus {
    color: #000;
    text-decoration: none !important; }
    .content-button-level-up:hover:before, .content-button-level-up:focus:before {
      color: #fff;
      background-color: #f7403b;
      border: 1px solid #f7403b; }
  .content-button-level-up:before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    position: absolute;
    top: 50%;
    left: 0px;
    display: inline-block;
    -ms-transform: translate(0%, -50%);
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    font-weight: 500;
    color: #000;
    font-size: 28px;
    font-size: 1.75rem;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    text-align: center;
    line-height: 26px;
    border: 1px solid #000;
    -moz-transition: all ease-in-out 150ms;
    -o-transition: all ease-in-out 150ms;
    -webkit-transition: all ease-in-out 150ms;
    transition: all ease-in-out 150ms; }
    @media (min-width: 992px) {
      .content-button-level-up:before {
        font-size: 35px;
        font-size: 2.1875rem;
        width: 35px;
        height: 35px;
        border-radius: 35px;
        text-align: center;
        line-height: 32px; } }

.content-mobile-menu {
  display: none;
  font-size: 16px;
  font-size: 1rem; }
  .content-mobile-menu:hover {
    color: #000 !important;
    background: #fff;
    border: 2px solid #000 !important; }
  .content-mobile-menu:after {
    display: inline-block;
    vertical-align: middle;
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e903";
    margin-left: 5px;
    margin-top: -3px;
    font-size: 35px;
    font-size: 2.1875rem;
    -webkit-transition-property: transform;
    -moz-transition-property: transform;
    -o-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 250ms;
    -moz-transition-duration: 250ms;
    -o-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease; }
  .content-mobile-menu.active:after {
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  @media (min-width: 320px) and (max-width: 767px) {
    .content-mobile-menu {
      display: block; } }

.content-menu,
.content-menu-generated {
  width: 107%;
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 20px;
  color: #000; }
  @media (min-width: 768px) {
    .content-menu,
    .content-menu-generated {
      display: block !important; } }
  @media (min-width: 768px) {
    .content-menu,
    .content-menu-generated {
      margin-top: 0px; } }
  .content-menu ul,
  .content-menu-generated ul {
    padding: 0;
    margin: 0;
    list-style: none; }
  .content-menu .content-menu-item > a,
  .content-menu li > a,
  .content-menu-generated .content-menu-item > a,
  .content-menu-generated li > a {
    display: inline-block;
    text-decoration: none;
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 24px;
    color: inherit;
    font-weight: 400;
    padding: 5px 5px;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    @media (min-width: 768px) {
      .content-menu .content-menu-item > a,
      .content-menu li > a,
      .content-menu-generated .content-menu-item > a,
      .content-menu-generated li > a {
        font-size: 22px;
        font-size: 1.375rem;
        line-height: 28px; } }
    @media (min-width: 992px) {
      .content-menu .content-menu-item > a,
      .content-menu li > a,
      .content-menu-generated .content-menu-item > a,
      .content-menu-generated li > a {
        font-size: 24px;
        font-size: 1.5rem;
        line-height: 30px;
        padding: 2px 0px; } }
    @media (min-width: 1200px) {
      .content-menu .content-menu-item > a,
      .content-menu li > a,
      .content-menu-generated .content-menu-item > a,
      .content-menu-generated li > a {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 35px; } }
    .content-menu .content-menu-item > a:hover, .content-menu .content-menu-item > a:focus,
    .content-menu li > a:hover,
    .content-menu li > a:focus,
    .content-menu-generated .content-menu-item > a:hover,
    .content-menu-generated .content-menu-item > a:focus,
    .content-menu-generated li > a:hover,
    .content-menu-generated li > a:focus {
      color: #f7403b; }
  .content-menu .content-menu-item.active > a,
  .content-menu li.active > a,
  .content-menu-generated .content-menu-item.active > a,
  .content-menu-generated li.active > a {
    color: #f7403b; }
  .content-menu .content-menu-item.active .content-submenu,
  .content-menu li.active .content-submenu,
  .content-menu-generated .content-menu-item.active .content-submenu,
  .content-menu-generated li.active .content-submenu {
    display: block; }
  .content-menu .content-menu-item .content-submenu,
  .content-menu li .content-submenu,
  .content-menu-generated .content-menu-item .content-submenu,
  .content-menu-generated li .content-submenu {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    padding: 0 7px;
    margin-bottom: 10px;
    text-decoration: none;
    display: none; }
    @media (min-width: 992px) {
      .content-menu .content-menu-item .content-submenu,
      .content-menu li .content-submenu,
      .content-menu-generated .content-menu-item .content-submenu,
      .content-menu-generated li .content-submenu {
        padding: 0 2px; } }
    .content-menu .content-menu-item .content-submenu .content-submenu-item > a,
    .content-menu li .content-submenu .content-submenu-item > a,
    .content-menu-generated .content-menu-item .content-submenu .content-submenu-item > a,
    .content-menu-generated li .content-submenu .content-submenu-item > a {
      position: relative;
      display: block;
      padding: 5px 0;
      color: #000;
      font-size: 16px;
      font-size: 1rem;
      line-height: 20px;
      font-weight: 600;
      text-decoration: none; }
      @media (min-width: 768px) {
        .content-menu .content-menu-item .content-submenu .content-submenu-item > a,
        .content-menu li .content-submenu .content-submenu-item > a,
        .content-menu-generated .content-menu-item .content-submenu .content-submenu-item > a,
        .content-menu-generated li .content-submenu .content-submenu-item > a {
          padding: 2px 0;
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media (min-width: 992px) {
        .content-menu .content-menu-item .content-submenu .content-submenu-item > a,
        .content-menu li .content-submenu .content-submenu-item > a,
        .content-menu-generated .content-menu-item .content-submenu .content-submenu-item > a,
        .content-menu-generated li .content-submenu .content-submenu-item > a {
          font-size: 16px;
          font-size: 1rem; } }
      @media (min-width: 1200px) {
        .content-menu .content-menu-item .content-submenu .content-submenu-item > a,
        .content-menu li .content-submenu .content-submenu-item > a,
        .content-menu-generated .content-menu-item .content-submenu .content-submenu-item > a,
        .content-menu-generated li .content-submenu .content-submenu-item > a {
          font-size: 18px;
          font-size: 1.125rem;
          line-height: 30px; } }
      .content-menu .content-menu-item .content-submenu .content-submenu-item > a:hover, .content-menu .content-menu-item .content-submenu .content-submenu-item > a:focus,
      .content-menu li .content-submenu .content-submenu-item > a:hover,
      .content-menu li .content-submenu .content-submenu-item > a:focus,
      .content-menu-generated .content-menu-item .content-submenu .content-submenu-item > a:hover,
      .content-menu-generated .content-menu-item .content-submenu .content-submenu-item > a:focus,
      .content-menu-generated li .content-submenu .content-submenu-item > a:hover,
      .content-menu-generated li .content-submenu .content-submenu-item > a:focus {
        color: #f7403b; }
    .content-menu .content-menu-item .content-submenu .content-submenu-item .active,
    .content-menu li .content-submenu .content-submenu-item .active,
    .content-menu-generated .content-menu-item .content-submenu .content-submenu-item .active,
    .content-menu-generated li .content-submenu .content-submenu-item .active {
      color: #f7403b; }

.content-list .row {
  margin-left: -15px;
  margin-right: -15px; }
  .content-list .row [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px; }
    @media (min-width: 992px) {
      .content-list .row [class*="col-"] {
        padding-left: 20px;
        padding-right: 20px; } }

.button-show-content,
.arrow-down-link {
  margin-top: 20px;
  margin-bottom: 30px; }

.content-gallery {
  margin: 30px 0;
  position: relative;
  max-width: 100%;
  overflow: hidden; }
  @media (min-width: 768px) {
    .content-gallery {
      margin: 45px 0; } }
  .content-gallery-list {
    margin: 0 -25px; }
    .content-gallery-list-item {
      width: auto;
      height: 140px !important;
      margin: 0 25px;
      position: relative;
      overflow: hidden;
      text-align: center; }
      .content-gallery-list-item img {
        width: auto;
        height: 140px;
        display: inline-block !important; }
        @media (min-width: 480px) {
          .content-gallery-list-item img {
            display: block;
            position: absolute;
            position: absolute;
            left: -200%;
            right: -200%;
            margin: auto !important;
            bottom: -200%;
            top: -200%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            display: block !important; } }
  .content-gallery-arrow-left, .content-gallery-arrow-right {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    transform: translateY(-50%);
    font-size: 0px;
    font-size: 0rem;
    cursor: pointer;
    z-index: 99; }
    .content-gallery-arrow-left:before, .content-gallery-arrow-right:before {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e90d";
      position: relative;
      display: inline-block;
      color: #000;
      font-weight: 600;
      font-size: 40px;
      font-size: 2.5rem;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      border: 2px solid #000;
      -moz-transition: all ease-in-out 150ms;
      -o-transition: all ease-in-out 150ms;
      -webkit-transition: all ease-in-out 150ms;
      transition: all ease-in-out 150ms;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; }
      @media (min-width: 480px) {
        .content-gallery-arrow-left:before, .content-gallery-arrow-right:before {
          color: #fff;
          border: 2px solid #fff; } }
    .content-gallery-arrow-left:focus:before, .content-gallery-arrow-left:hover:before, .content-gallery-arrow-right:focus:before, .content-gallery-arrow-right:hover:before {
      color: #f7403b;
      border-color: #f7403b; }
      @media (min-width: 480px) {
        .content-gallery-arrow-left:focus:before, .content-gallery-arrow-left:hover:before, .content-gallery-arrow-right:focus:before, .content-gallery-arrow-right:hover:before {
          box-shadow: 0 0 5px #000; } }
  .content-gallery-arrow-left {
    left: 10px; }
  .content-gallery-arrow-right {
    right: 10px; }
    .content-gallery-arrow-right:before {
      content: "\e908"; }

.gallery-wrapper {
  margin-top: 10px; }
  @media (min-width: 768px) {
    .gallery-wrapper {
      margin-top: 15px; } }

.gallery-card {
  display: block;
  margin-bottom: 20px;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  @media (min-width: 768px) {
    .gallery-card {
      margin-bottom: 30px; } }
  .gallery-card-img {
    position: relative;
    overflow: hidden; }
    .gallery-card-img:before {
      content: "";
      display: block;
      padding-top: 65%; }
    .gallery-card-img img {
      display: block;
      position: absolute;
      position: absolute;
      left: -200%;
      right: -200%;
      margin: auto !important;
      bottom: -200%;
      top: -200%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover; }
  .gallery-card.video .gallery-card-img:after {
    content: "\e906";
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 40px;
    font-size: 2.5rem;
    height: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    color: #009641; }
    @media (min-width: 992px) {
      .gallery-card.video .gallery-card-img:after {
        font-size: 60px;
        font-size: 3.75rem; } }
  .gallery-card-title {
    font-size: 22px;
    font-size: 1.375rem;
    margin-top: 5px; }
    @media (min-width: 992px) {
      .gallery-card-title {
        font-size: 28px;
        font-size: 1.75rem;
        margin-top: 10px; } }
  .gallery-card:hover, .gallery-card:focus {
    outline: 0;
    border: 0; }
    .gallery-card:hover.video, .gallery-card:focus.video {
      color: #000; }
      .gallery-card:hover.video .gallery-card-img:after, .gallery-card:focus.video .gallery-card-img:after {
        color: #f7403b; }

.card-list-wrapper {
  margin-top: 10px; }
  @media (min-width: 768px) {
    .card-list-wrapper {
      margin-top: 15px; } }

.card-list-item {
  display: block;
  position: relative;
  margin-bottom: 20px;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  height: -webkit-calc(100% - 20px);
  height: -moz-calc(100% - 20px);
  height: calc(100% - 20px); }
  @media (min-width: 545px) {
    .card-list-item {
      padding-bottom: 40px; } }
  @media (min-width: 768px) {
    .card-list-item {
      padding-bottom: 50px;
      margin-bottom: 30px;
      height: -webkit-calc(100% - 30px);
      height: -moz-calc(100% - 30px);
      height: calc(100% - 30px); } }
  .card-list-item-img {
    position: relative;
    overflow: hidden; }
    .card-list-item-img:before {
      content: "";
      display: block;
      padding-top: 65%; }
    .card-list-item-img img {
      display: block;
      position: absolute;
      position: absolute;
      left: -200%;
      right: -200%;
      margin: auto !important;
      bottom: -200%;
      top: -200%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      height: auto;
      width: 100%; }
  .card-list-item-title {
    font-size: 22px;
    font-size: 1.375rem;
    margin-top: 7px;
    line-height: 1.15; }
    @media (min-width: 992px) {
      .card-list-item-title {
        font-size: 28px;
        font-size: 1.75rem;
        margin-top: 12px; } }
  .card-list-item-perex {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25; }
    @media (min-width: 768px) {
      .card-list-item-perex {
        font-size: 18px;
        font-size: 1.125rem; } }
  .card-list-item-bottom {
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
    font-size: 0.875rem; }
    @media (min-width: 545px) {
      .card-list-item-bottom {
        position: absolute;
        bottom: 0;
        left: 0; } }
    @media (min-width: 992px) {
      .card-list-item-bottom {
        margin-top: 15px;
        font-size: 18px;
        font-size: 1.125rem; } }
    .card-list-item-bottom:after {
      content: "\e908";
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: inline-block;
      font-size: 25px;
      font-size: 1.5625rem;
      color: #000;
      font-weight: 600;
      border: 2px solid #000;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      border-radius: 100%; }
      @media (min-width: 768px) {
        .card-list-item-bottom:after {
          font-size: 30px;
          font-size: 1.875rem; } }
      @media (min-width: 992px) {
        .card-list-item-bottom:after {
          font-size: 35px;
          font-size: 2.1875rem; } }
  .card-list-item.product, .card-list-item.hp-news {
    padding-bottom: 30px;
    margin-bottom: 35px;
    height: -webkit-calc(100% - 30px);
    height: -moz-calc(100% - 30px);
    height: calc(100% - 30px); }
    @media (min-width: 768px) {
      .card-list-item.product, .card-list-item.hp-news {
        padding-bottom: 45px;
        margin-bottom: 50px;
        height: -webkit-calc(100% - 50px);
        height: -moz-calc(100% - 50px);
        height: calc(100% - 50px); } }
    .card-list-item.product:after, .card-list-item.hp-news:after {
      content: "\e908";
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: inline-block;
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 25px;
      font-size: 1.5625rem;
      color: #000;
      font-weight: 600;
      border: 2px solid #000;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      border-radius: 100%; }
      @media (min-width: 768px) {
        .card-list-item.product:after, .card-list-item.hp-news:after {
          font-size: 30px;
          font-size: 1.875rem; } }
      @media (min-width: 992px) {
        .card-list-item.product:after, .card-list-item.hp-news:after {
          font-size: 35px;
          font-size: 2.1875rem; } }
  .card-list-item.hp-news .card-list-item-img:before {
    padding-top: 50%; }
  .card-list-item.line .card-list-item-title {
    border-bottom: 1px solid #000;
    padding-bottom: 10px; }
    @media (min-width: 768px) {
      .card-list-item.line .card-list-item-title {
        border-bottom: 2px solid #000;
        padding-bottom: 10px; } }
  .card-list-item.slim-image .card-list-item-img:before {
    padding-top: 55%; }
  .card-list-item.quick-info {
    padding: 10px 0; }
    .card-list-item.quick-info .card-list-item-img:before {
      padding-top: 45%; }
      @media (min-width: 768px) {
        .card-list-item.quick-info .card-list-item-img:before {
          padding-top: 65%; } }
      @media (min-width: 1200px) {
        .card-list-item.quick-info .card-list-item-img:before {
          padding-top: 45%; } }
    .card-list-item.quick-info .card-list-item-perex {
      font-weight: 400; }
    .card-list-item.quick-info .card-list-item-bottom:after {
      display: none; }
  .card-list-item:hover:not(.quick-info), .card-list-item:focus:not(.quick-info) {
    outline: 0;
    border: 0;
    color: #000; }
    .card-list-item:hover:not(.quick-info).product:after, .card-list-item:hover:not(.quick-info).hp-news:after, .card-list-item:focus:not(.quick-info).product:after, .card-list-item:focus:not(.quick-info).hp-news:after {
      color: #fff;
      background-color: #f7403b;
      border: 2px solid #f7403b; }
    .card-list-item:hover:not(.quick-info) .card-list-item-bottom:after, .card-list-item:focus:not(.quick-info) .card-list-item-bottom:after {
      color: #fff;
      background-color: #f7403b;
      border: 2px solid #f7403b; }

.full-width-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 10px 0;
  margin: 0;
  border-bottom: 1px solid #000;
  min-height: 91px;
  position: relative;
  -webkit-transition-property: background-color, color;
  -moz-transition-property: background-color, color;
  -o-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  -o-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease; }
  @media (min-width: 480px) {
    .full-width-item {
      min-height: 155px;
      padding: 10px 10px; } }
  @media (min-width: 768px) {
    .full-width-item {
      min-height: 184px; } }
  @media (min-width: 992px) {
    .full-width-item:hover, .full-width-item:focus {
      background-color: #f7403b; } }
  .full-width-item:hover .full-width-item-content:before, .full-width-item:focus .full-width-item-content:before {
    background-color: #000; }
  @media (min-width: 992px) {
    .full-width-item:hover .full-width-item-content:after, .full-width-item:focus .full-width-item-content:after {
      color: #fff; } }
  @media (min-width: 992px) {
    .full-width-item:hover .full-width-item-content-button, .full-width-item:focus .full-width-item-content-button {
      color: #009641 !important;
      background: #000 !important;
      text-decoration: none !important; } }
  .full-width-item-img {
    width: auto;
    -webkit-flex: 0 0 100px;
    -moz-flex: 0 0 100px;
    -ms-flex: 0 0 100px;
    -webkit-flex: 0 0 100px;
    flex: 0 0 100px;
    position: relative; }
    @media (min-width: 480px) {
      .full-width-item-img {
        -webkit-flex: 0 0 160px;
        -moz-flex: 0 0 160px;
        -ms-flex: 0 0 160px;
        -webkit-flex: 0 0 160px;
        flex: 0 0 160px; } }
    @media (min-width: 768px) {
      .full-width-item-img {
        -webkit-flex: 0 0 225px;
        -moz-flex: 0 0 225px;
        -ms-flex: 0 0 225px;
        -webkit-flex: 0 0 225px;
        flex: 0 0 225px; } }
    .full-width-item-img img {
      position: relative;
      max-width: 100%;
      max-height: 100%; }
      @media (min-width: 480px) {
        .full-width-item-img img {
          top: 50%;
          -ms-transform: translate(0, -50%);
          -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); } }
  .full-width-item-content {
    position: relative;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    padding-left: 10px;
    text-align: left;
    padding-right: 30px; }
    @media (min-width: 480px) {
      .full-width-item-content {
        padding-right: 0px; } }
    @media (min-width: 768px) {
      .full-width-item-content {
        padding-left: 20px; } }
    .full-width-item-content-title {
      font-size: 15px;
      font-size: 0.9375rem;
      border-bottom: 0; }
      @media (min-width: 320px) and (max-width: 479px) {
        .full-width-item-content-title {
          line-height: 19px;
          font-weight: 800;
          text-decoration: underline; } }
      @media (min-width: 480px) {
        .full-width-item-content-title {
          border-bottom: 2px solid #000; } }
      @media (min-width: 545px) {
        .full-width-item-content-title {
          font-size: 18px;
          font-size: 1.125rem;
          padding-bottom: 5px; } }
      @media (min-width: 768px) {
        .full-width-item-content-title {
          font-size: 20px;
          font-size: 1.25rem; } }
      @media (min-width: 1200px) {
        .full-width-item-content-title {
          font-size: 22px;
          font-size: 1.375rem; } }
    @media (min-width: 480px) {
      .full-width-item-content-perex {
        padding-bottom: 25px; } }
    .full-width-item-content:before {
      content: "";
      position: absolute;
      display: none;
      bottom: 2px;
      left: 17px;
      width: 27px;
      height: 27px;
      -webkit-border-radius: 27px;
      -moz-border-radius: 27px;
      -ms-border-radius: 27px;
      border-radius: 27px;
      background-color: transparent;
      -moz-transition: all ease-in-out 100ms;
      -o-transition: all ease-in-out 100ms;
      -webkit-transition: all ease-in-out 100ms;
      transition: all ease-in-out 100ms; }
      @media (min-width: 992px) {
        .full-width-item-content:before {
          display: block; } }
    .full-width-item-content:after {
      content: "\e908";
      position: absolute;
      display: block;
      top: 0px;
      right: 0px;
      font-size: 25px;
      font-size: 1.5625rem;
      -moz-transition: all ease-in-out 100ms;
      -o-transition: all ease-in-out 100ms;
      -webkit-transition: all ease-in-out 100ms;
      transition: all ease-in-out 100ms; }
      @media (min-width: 480px) {
        .full-width-item-content:after {
          top: auto;
          bottom: 0px;
          left: 5px;
          font-size: 30px;
          font-size: 1.875rem; } }
      @media (min-width: 768px) {
        .full-width-item-content:after {
          bottom: 0px;
          left: 15px; } }
      @media (min-width: 545px) and (max-width: 991px) {
        .full-width-item-content:after {
          color: #000 !important; } }

.content-tabs {
  margin-top: 25px; }
  .content-tabs-title {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    border-bottom: 2px solid #000 !important; }
    .content-tabs-title-link {
      -webkit-flex: 0 1 auto;
      -moz-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      -webkit-flex: 0 1 auto;
      flex: 0 1 auto;
      display: inline-block;
      margin-bottom: -5px;
      padding: 2px 20px 2px 0;
      font-size: 14px;
      font-size: 0.875rem;
      color: #000;
      text-decoration: none;
      text-transform: capitalize;
      font-weight: 600; }
      @media (min-width: 545px) {
        .content-tabs-title-link {
          padding: 5px 30px 5px 0;
          font-size: 16px;
          font-size: 1rem; } }
      @media (min-width: 768px) {
        .content-tabs-title-link {
          padding: 8px 40px 8px 0;
          font-size: 18px;
          font-size: 1.125rem; } }
      .content-tabs-title-link.active, .content-tabs-title-link:focus, .content-tabs-title-link:hover {
        color: #f7403b;
        text-decoration: none !important; }
  .content-tabs-list-item {
    display: inline-block;
    width: 100%;
    height: 50px;
    padding-top: 10px;
    text-decoration: none; }
    .content-tabs-list-item:focus, .content-tabs-list-item:hover {
      text-decoration: underline;
      color: #000; }
    .content-tabs-list-item-image {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 65%;
      border: 1px solid #000;
      overflow: hidden; }
      .content-tabs-list-item-image img {
        display: block;
        position: absolute;
        position: absolute;
        left: -200%;
        right: -200%;
        margin: auto !important;
        bottom: -200%;
        top: -200%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover; }
    .content-tabs-list-item-name {
      margin-top: 5px;
      font-size: 11px;
      font-size: 0.6875rem;
      color: #000; }
      @media (min-width: 480px) {
        .content-tabs-list-item-name {
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media (min-width: 768px) {
        .content-tabs-list-item-name {
          margin-top: 10px; } }
      @media (min-width: 1200px) {
        .content-tabs-list-item-name {
          font-size: 14px;
          font-size: 0.875rem; } }
  .content-tabs-list .row {
    margin-left: -15px;
    margin-right: -15px; }
    .content-tabs-list .row [class*="col-"] {
      padding-left: 13px;
      padding-right: 13px; }

.load-more-products {
  display: none; }

.contact-box {
  padding: 10px;
  position: relative;
  border: 2px solid #000; }
  @media (min-width: 768px) {
    .contact-box {
      padding: 7px; } }
  @media (min-width: 992px) {
    .contact-box {
      padding: 12px; } }
  .contact-box h2 {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: normal;
    padding-bottom: 5px;
    border-bottom: 2px solid #000;
    margin-bottom: 5px;
    margin-top: 0px !important; }
    @media (min-width: 992px) {
      .contact-box h2 {
        font-size: 22px;
        font-size: 1.375rem;
        padding-bottom: 7px;
        line-height: normal;
        margin-bottom: 5px; } }
  .contact-box-text {
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: bold;
    min-height: 50px; }
    @media (min-width: 480px) and (max-width: 767px) {
      .contact-box-text {
        font-size: 14px;
        font-size: 0.875rem;
        min-height: 0; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .contact-box-text {
        font-size: 13px;
        font-size: 0.8125rem;
        min-height: 0; } }
    @media (min-width: 992px) {
      .contact-box-text {
        min-height: 50px; } }
    .contact-box-text table {
      border-collapse: collapse;
      font-weight: 400; }
      .contact-box-text table td {
        padding-right: 5px; }
        @media (min-width: 480px) {
          .contact-box-text table td {
            font-size: 16px;
            font-size: 1rem; } }
        @media (min-width: 768px) {
          .contact-box-text table td {
            font-size: 13px;
            font-size: 0.8125rem; } }
        @media (min-width: 768px) and (max-width: 850px) {
          .contact-box-text table td {
            font-size: 11px;
            font-size: 0.6875rem; } }
        @media (min-width: 992px) {
          .contact-box-text table td {
            font-size: 15px;
            font-size: 0.9375rem; } }
        @media (min-width: 1400px) {
          .contact-box-text table td {
            font-size: 16px;
            font-size: 1rem; } }
      .contact-box-text table a {
        color: #000;
        text-decoration: none;
        cursor: pointer; }
        .contact-box-text table a:hover, .contact-box-text table a:focus {
          color: #000;
          text-decoration: none; }
  .contact-box .contact-box-button {
    width: -webkit-calc(100% - 2px);
    width: -moz-calc(100% - 2px);
    width: calc(100% - 2px);
    position: absolute;
    bottom: 12px;
    right: 12px;
    width: auto;
    font-size: 13px;
    font-size: 0.8125rem;
    cursor: pointer; }
    @media (min-width: 480px) {
      .contact-box .contact-box-button {
        right: 27px;
        min-width: 128px;
        font-size: 14px;
        font-size: 0.875rem; } }
    @media (min-width: 768px) {
      .contact-box .contact-box-button {
        position: static; } }
    @media (min-width: 992px) {
      .contact-box .contact-box-button {
        min-width: 155px; } }

.about-us-peoples-title {
  background-color: #e0e0e0;
  padding: 5px 10px;
  margin: 0;
  border-bottom: 0; }

.about-us-peoples-item {
  margin-bottom: 30px;
  line-height: 18px;
  font-size: 13px;
  font-size: 0.8125rem; }
  @media (min-width: 992px) {
    .about-us-peoples-item {
      font-size: 14px;
      font-size: 0.875rem; } }
  .about-us-peoples-item-title {
    font-size: 14px;
    font-size: 0.875rem;
    margin-bottom: 3px; }
    @media (min-width: 768px) {
      .about-us-peoples-item-title {
        font-size: 16px;
        font-size: 1rem;
        margin-bottom: 5px; } }
    @media (min-width: 992px) {
      .about-us-peoples-item-title {
        font-size: 18px;
        font-size: 1.125rem; } }
  .about-us-peoples-item-image {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 67.5%;
    margin-bottom: 5px;
    background-color: #009641;
    overflow: hidden; }
    @media (min-width: 768px) {
      .about-us-peoples-item-image {
        margin-bottom: 15px;
        padding-bottom: 105%; } }
    .about-us-peoples-item-image img {
      position: absolute;
      bottom: 0;
      left: 50%;
      -ms-transform: translate(-50%, 0);
      -webkit-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
      width: auto;
      height: 100%; }
      @media (min-width: 768px) {
        .about-us-peoples-item-image img {
          width: 100%;
          height: auto; } }

.events-tabs .content-tabs-title-link {
  background-color: transparent;
  text-transform: none; }
  @media (min-width: 320px) and (max-width: 479px) {
    .events-tabs .content-tabs-title-link {
      font-size: 12px;
      font-size: 0.75rem; } }
  .events-tabs .content-tabs-title-link:focus, .events-tabs .content-tabs-title-link:hover {
    color: #f7403b; }
  .events-tabs .content-tabs-title-link.active {
    color: #f7403b; }

.events-tabs .content-tabs-list-cont {
  padding-top: 10px; }
  @media (min-width: 768px) {
    .events-tabs .content-tabs-list-cont {
      padding-top: 25px; } }

.events-tabs-item {
  margin-bottom: 10px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  background-color: #e0e0e0; }
  .events-tabs-item-image {
    width: 40%;
    min-height: 95px;
    padding: 5px;
    text-align: center; }
    @media (min-width: 480px) {
      .events-tabs-item-image {
        min-height: 135px;
        padding: 15px 12.5px; } }
    @media (min-width: 768px) {
      .events-tabs-item-image {
        padding: 25px 15.5px 25px 19.5px; } }
    @media (min-width: 992px) {
      .events-tabs-item-image {
        width: 27.5%;
        min-height: 168px; } }
    .events-tabs-item-image img {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      max-width: 100%;
      max-height: 100%;
      margin: 0px !important; }
  .events-tabs-item-text {
    position: relative;
    width: 75%;
    padding: 5px 5px 35px 5px;
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 15px; }
    @media (min-width: 545px) {
      .events-tabs-item-text {
        padding: 10px;
        padding-bottom: 50px;
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 18px; } }
    .events-tabs-item-text-title {
      font-size: 14px;
      font-size: 0.875rem;
      font-weight: bold;
      line-height: 18px; }
      @media (min-width: 480px) {
        .events-tabs-item-text-title {
          font-size: 16px;
          font-size: 1rem;
          line-height: 25px; } }
      @media (min-width: 992px) {
        .events-tabs-item-text-title {
          font-size: 18px;
          font-size: 1.125rem;
          line-height: 35px; } }
    .events-tabs-item-text-date {
      position: absolute;
      bottom: 5px;
      right: 5px;
      font-size: 13px;
      font-size: 0.8125rem;
      font-weight: 600; }
      @media (min-width: 480px) {
        .events-tabs-item-text-date {
          bottom: 20px;
          right: 15px;
          font-size: 14px;
          font-size: 0.875rem; } }
      @media (min-width: 992px) {
        .events-tabs-item-text-date {
          font-size: 18px;
          font-size: 1.125rem; } }

.newsletter-box {
  background-color: #e0e0e0;
  padding: 9px 10px;
  margin-bottom: 25px; }
  @media (min-width: 992px) {
    .newsletter-box {
      padding: 9px 18px; } }
  .newsletter-box .form-horizontal {
    position: relative;
    width: 100%; }
    @media (min-width: 545px) {
      .newsletter-box .form-horizontal {
        display: -ms-inline-flexbox;
        display: -webkit-inline-flex;
        display: inline-flex; } }
  .newsletter-box .form-group {
    margin: 0; }
    @media (min-width: 545px) {
      .newsletter-box .form-group {
        display: -ms-inline-flexbox;
        display: -webkit-inline-flex;
        display: inline-flex;
        width: -webkit-calc(100% - 120px);
        width: -moz-calc(100% - 120px);
        width: calc(100% - 120px); } }
  .newsletter-box .editing-form-label-cell {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 24px; }
    @media (min-width: 480px) {
      .newsletter-box .editing-form-label-cell {
        font-size: 17px;
        font-size: 1.0625rem; } }
    @media (min-width: 768px) {
      .newsletter-box .editing-form-label-cell {
        width: 60%; } }
    @media (min-width: 992px) {
      .newsletter-box .editing-form-label-cell {
        font-size: 22px;
        font-size: 1.375rem; } }
    .newsletter-box .editing-form-label-cell .control-label {
      margin: 0px;
      padding-bottom: 5px; }
      @media (min-width: 545px) {
        .newsletter-box .editing-form-label-cell .control-label {
          padding-bottom: 0px;
          padding-top: 5px; } }
  .newsletter-box .editing-form-value-cell {
    display: inline-block;
    width: -webkit-calc(100% - 100px);
    width: -moz-calc(100% - 100px);
    width: calc(100% - 100px); }
    @media (min-width: 480px) {
      .newsletter-box .editing-form-value-cell {
        width: -webkit-calc(100% - 120px);
        width: -moz-calc(100% - 120px);
        width: calc(100% - 120px); } }
    @media (min-width: 768px) {
      .newsletter-box .editing-form-value-cell {
        width: 40%;
        padding-right: 5px; } }
    .newsletter-box .editing-form-value-cell .form-control {
      display: inline;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0;
      height: 32px;
      border: 1px solid #fff;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      outline: 0; }
      .newsletter-box .editing-form-value-cell .form-control:focus {
        border-color: #000; }
  .newsletter-box .form-group-submit {
    position: absolute;
    display: inline-block;
    width: 120px;
    text-align: right;
    bottom: 0;
    right: 0; }
    @media (min-width: 545px) {
      .newsletter-box .form-group-submit {
        position: static;
        width: 120px;
        padding-left: 5px; } }
    .newsletter-box .form-group-submit .SubscriptionButton {
      display: inline-block;
      width: 90px;
      border: 1px solid #000;
      color: #000;
      background-color: transparent;
      font-size: 15px;
      font-size: 0.9375rem;
      line-height: 20px;
      font-weight: 600;
      padding: 5px 0;
      -webkit-transition-property: background-color, color;
      -moz-transition-property: background-color, color;
      -o-transition-property: background-color, color;
      transition-property: background-color, color;
      -webkit-transition-duration: 250ms;
      -moz-transition-duration: 250ms;
      -o-transition-duration: 250ms;
      transition-duration: 250ms;
      -webkit-transition-timing-function: ease;
      -moz-transition-timing-function: ease;
      -o-transition-timing-function: ease;
      transition-timing-function: ease;
      cursor: pointer;
      outline: 0; }
      @media (min-width: 480px) {
        .newsletter-box .form-group-submit .SubscriptionButton {
          width: 110px; } }
      @media (min-width: 768px) {
        .newsletter-box .form-group-submit .SubscriptionButton {
          width: 100%; } }
      @media (min-width: 768px) {
        .newsletter-box .form-group-submit .SubscriptionButton {
          font-size: 16px;
          font-size: 1rem; } }
      @media (min-width: 992px) {
        .newsletter-box .form-group-submit .SubscriptionButton {
          font-size: 14px;
          font-size: 0.875rem; } }
      .newsletter-box .form-group-submit .SubscriptionButton:focus, .newsletter-box .form-group-submit .SubscriptionButton:hover {
        background-color: #000;
        color: #fff; }
  .newsletter-box .ErrorMessage {
    display: block;
    background-color: #009641;
    margin-bottom: 10px;
    font-weight: 600; }

@media (min-width: 545px) {
  .faq-tabs {
    margin-top: 10px; } }

@media (min-width: 992px) {
  .faq-tabs {
    margin-top: 25px; } }

.faq-tabs-item {
  background-color: #e0e0e0;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  margin-bottom: 10px; }
  .faq-tabs-item-link {
    padding: 10px;
    cursor: pointer;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    @media (min-width: 545px) {
      .faq-tabs-item-link {
        padding: 15px; } }
    @media (min-width: 992px) {
      .faq-tabs-item-link {
        padding: 17px 20px; } }
    .faq-tabs-item-link h2 {
      margin: 0px;
      font-size: 16px;
      font-size: 1rem;
      line-height: 22px; }
      @media (min-width: 480px) {
        .faq-tabs-item-link h2 {
          font-weight: 800; } }
      @media (min-width: 545px) {
        .faq-tabs-item-link h2 {
          font-size: 18px;
          font-size: 1.125rem;
          line-height: 27px; } }
      @media (min-width: 992px) {
        .faq-tabs-item-link h2 {
          font-size: 22px;
          font-size: 1.375rem;
          line-height: 35px; } }
    .faq-tabs-item-link.active h2 {
      background-image: linear-gradient(#000 2px, transparent 2px);
      background-size: 100% 22px;
      background-position: 0 20px; }
      @media (min-width: 545px) {
        .faq-tabs-item-link.active h2 {
          background-size: 100% 27px;
          background-position: 0 25px; } }
      @media (min-width: 992px) {
        .faq-tabs-item-link.active h2 {
          background-size: 100% 35px;
          background-position: 0 33px; } }
    .faq-tabs-item-link.active:focus, .faq-tabs-item-link.active:hover {
      background-color: transparent; }
  .faq-tabs-item-content {
    display: none;
    padding: 0 10px 10px 10px;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 18px; }
    @media (min-width: 545px) {
      .faq-tabs-item-content {
        padding: 0 15px 15px 15px; } }
    @media (min-width: 992px) {
      .faq-tabs-item-content {
        padding: 0 20px 17px 20px; } }
    .faq-tabs-item-content a {
      color: blue;
      text-decoration: underline; }
      .faq-tabs-item-content a:focus, .faq-tabs-item-content a:hover {
        color: blue;
        text-decoration: none; }
  .faq-tabs-item:focus, .faq-tabs-item:hover {
    background-color: #f7403b; }
  .faq-tabs-item.active {
    background-color: #009641; }
    .faq-tabs-item.active:focus, .faq-tabs-item.active:hover {
      background-color: #009641; }

.locked-box {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  background-color: #e0e0e0;
  padding: 5px 10px;
  margin-top: 45px;
  margin-bottom: 35px; }
  @media (min-width: 992px) {
    .locked-box {
      padding: 5px 15px; } }
  .locked-box-text {
    position: relative;
    padding-left: 30px;
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 17px; }
    @media (min-width: 480px) {
      .locked-box-text {
        font-size: 17px;
        font-size: 1.0625rem;
        line-height: 19px; } }
    @media (min-width: 545px) {
      .locked-box-text {
        font-size: 19px;
        font-size: 1.1875rem;
        line-height: 21px; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .locked-box-text {
        padding-left: 40px;
        font-size: 17px;
        font-size: 1.0625rem;
        line-height: 19px; } }
    @media (min-width: 1200px) {
      .locked-box-text {
        font-size: 22px;
        font-size: 1.375rem;
        line-height: 25px; } }
    .locked-box-text:before {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      position: absolute;
      top: 50%;
      left: -5px;
      font-size: 20px;
      font-size: 1.25rem;
      -ms-transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      color: #000; }
      @media (min-width: 545px) {
        .locked-box-text:before {
          font-size: 22px;
          font-size: 1.375rem; } }
      @media (min-width: 1200px) {
        .locked-box-text:before {
          font-size: 25px;
          font-size: 1.5625rem; } }
  .locked-box-submit {
    -webkit-flex: 0 0 90px;
    -moz-flex: 0 0 90px;
    -ms-flex: 0 0 90px;
    -webkit-flex: 0 0 90px;
    flex: 0 0 90px;
    padding-left: 10px; }
    @media (min-width: 545px) {
      .locked-box-submit {
        -webkit-flex-basis: 110px;
        -moz-flex-basis: 110px;
        -ms-flex-basis: 110px;
        -webkit-flex-basis: 110px;
        flex-basis: 110px; } }
    @media (min-width: 992px) {
      .locked-box-submit {
        -webkit-flex-basis: 160px;
        -moz-flex-basis: 160px;
        -ms-flex-basis: 160px;
        -webkit-flex-basis: 160px;
        flex-basis: 160px; } }
    .locked-box-submit .button-sign-up {
      width: 100%;
      min-height: 32px;
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 16px;
      padding: 8px 5px; }

.download-cont {
  padding-bottom: 30px; }

.download-item {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  min-height: 40px;
  padding: 5px 0px 5px 30px;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  color: #000;
  text-decoration: none;
  margin-bottom: 15px;
  margin-top: 0px;
  padding-left: 37px;
  font-size: 14px;
  font-size: 0.875rem; }
  @media (min-width: 768px) {
    .download-item {
      padding-left: 37px;
      margin-bottom: 25px;
      font-size: 16px;
      font-size: 1rem; } }
  .download-item:hover, .download-item:focus {
    color: #f7403b;
    text-decoration: none !important; }
  .download-item:before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    position: absolute;
    top: 19px;
    left: 0;
    font-size: 20px;
    font-size: 1.25rem;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    color: inherit; }
  .download-item h3 {
    font-size: 16px;
    font-size: 1rem;
    margin: 0;
    padding: 0;
    font-weight: 400;
    line-height: normal; }
    @media (min-width: 768px) {
      .download-item h3 {
        font-size: 18px;
        font-size: 1.125rem; } }

.download-small-cont {
  padding-bottom: 30px;
  margin-top: 20px; }
  @media (min-width: 992px) {
    .download-small-cont {
      margin-top: 40px; } }
  .download-small-cont .product-card-title {
    margin-bottom: 5px; }
  .download-small-cont .download-item {
    margin-top: 10px;
    margin-bottom: 0px; }

.subscribed .locked-box {
  display: none; }

.unsubscribed .download-item.locked:before {
  content: "\e90a"; }

.big-gallery-item {
  display: block;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 600; }
  @media (min-width: 545px) {
    .big-gallery-item {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 18px; } }
  @media (min-width: 768px) {
    .big-gallery-item {
      margin-top: 20px;
      font-size: 15px;
      font-size: 0.9375rem; } }
  .big-gallery-item:hover, .big-gallery-item:focus {
    text-decoration: none !important; }
  .big-gallery-item-image {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0px;
    padding-bottom: 82%;
    margin-bottom: 5px; }
    .big-gallery-item-image img {
      display: block;
      position: absolute;
      position: absolute;
      left: -200%;
      right: -200%;
      margin: auto !important;
      bottom: -200%;
      top: -200%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover; }

.logos-gallery-item {
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  margin-bottom: 20px;
  font-weight: 600; }
  .logos-gallery-item-image {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .logos-gallery-item-image {
        margin-bottom: 25px; } }
    .logos-gallery-item-image img {
      max-width: 100%;
      max-height: 100%; }
  .logos-gallery-item-links {
    font-weight: 400; }
    .logos-gallery-item-links a {
      color: #009641;
      text-decoration: underline;
      display: inline-block;
      margin-top: 10px; }
      @media (min-width: 768px) {
        .logos-gallery-item-links a {
          margin-top: 0px; } }
      .logos-gallery-item-links a:focus, .logos-gallery-item-links a:hover {
        color: #009641;
        text-decoration: none; }

.social-media-card:not(:last-of-type) {
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    .social-media-card:not(:last-of-type) {
      margin-bottom: 0px; } }

.social-media-card-title {
  padding: 5px 10px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 16px;
  font-size: 1rem;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 0px; }
  @media (min-width: 992px) {
    .social-media-card-title {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 22px;
      padding: 15px 10px; } }

.social-media-card-content {
  display: block; }
  @media (min-width: 480px) {
    .social-media-card-content {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; } }
  @media (min-width: 480px) and (max-width: 544px) {
    .social-media-card-content {
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      flex-wrap: wrap; } }
  .social-media-card-content-icon {
    position: relative;
    text-align: center;
    padding: 15px 10px;
    margin-top: 10px;
    font-size: 0;
    min-height: 60px; }
    @media (min-width: 480px) {
      .social-media-card-content-icon {
        -webkit-flex: 0 0 100%;
        -moz-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        -webkit-flex: 0 0 100%;
        flex: 0 0 100%; } }
    @media (min-width: 545px) {
      .social-media-card-content-icon {
        -webkit-flex: 0 0 65px;
        -moz-flex: 0 0 65px;
        -ms-flex: 0 0 65px;
        -webkit-flex: 0 0 65px;
        flex: 0 0 65px;
        margin-top: 0px; } }
    .social-media-card-content-icon:before {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-size: 40px;
      font-size: 2.5rem;
      color: #009641; }
      @media (min-width: 545px) {
        .social-media-card-content-icon:before {
          font-size: 45px;
          font-size: 2.8125rem; } }
  .social-media-card-content .s-google:before {
    content: ""; }
  .social-media-card-content .s-tumblr:before {
    content: ""; }
  .social-media-card-content .s-facebook:before {
    content: ""; }
  .social-media-card-content .s-mail:before {
    content: ""; }
  .social-media-card-content .s-twitter:before {
    content: ""; }
  .social-media-card-content .s-linkedin:before {
    content: ""; }
  .social-media-card-content .s-vine:before {
    content: ""; }
  .social-media-card-content .s-youtube:before {
    content: ""; }
  .social-media-card-content-text-first, .social-media-card-content-text-second {
    text-align: left;
    padding: 15px 10px;
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 18px; }
    @media (min-width: 480px) and (max-width: 544px) {
      .social-media-card-content-text-first, .social-media-card-content-text-second {
        -webkit-flex: 0 0 50%;
        -moz-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        -webkit-flex: 0 0 50%;
        flex: 0 0 50%; } }
    @media (min-width: 545px) {
      .social-media-card-content-text-first, .social-media-card-content-text-second {
        -webkit-flex: 0 1 55%;
        -moz-flex: 0 1 55%;
        -ms-flex: 0 1 55%;
        -webkit-flex: 0 1 55%;
        flex: 0 1 55%; } }
    .social-media-card-content-text-first h3,
    .social-media-card-content-text-first h4, .social-media-card-content-text-second h3,
    .social-media-card-content-text-second h4 {
      font-size: 15px;
      font-size: 0.9375rem;
      line-height: 18px;
      font-weight: 600;
      margin: 0; }
  @media (min-width: 545px) {
    .social-media-card-content-text-second {
      -webkit-flex: 0 1 45%;
      -moz-flex: 0 1 45%;
      -ms-flex: 0 1 45%;
      -webkit-flex: 0 1 45%;
      flex: 0 1 45%; } }

.social-media-card-join {
  padding: 15px 10px;
  text-align: right;
  border-bottom: 1px solid #e0e0e0; }
  .social-media-card-join a {
    position: relative;
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 30px;
    color: #009641;
    padding-right: 40px;
    font-weight: 600; }
    .social-media-card-join a:hover, .social-media-card-join a:focus {
      text-decoration: none; }
    .social-media-card-join a:after {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 30px;
      font-size: 1.875rem;
      content: "\e907";
      font-weight: 600;
      position: absolute;
      top: 50%;
      right: 0;
      -ms-transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      color: #009641;
      cursor: pointer; }

.info-box {
  background-color: #009641;
  margin-top: 40px;
  font-size: 16px;
  font-size: 1rem;
  line-height: 20px;
  font-weight: 800;
  padding: 7px 15px;
  display: inline-block; }
  @media (min-width: 768px) {
    .info-box {
      display: block; } }
  @media (min-width: 992px) {
    .info-box {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 23px; } }

.editor-base {
  font-family: 'GT-America';
  font-size: 16px;
  font-size: 1rem;
  line-height: 22px; }
  @media (min-width: 992px) {
    .editor-base {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 24px; } }
  .editor-base img {
    margin: 20px 0;
    max-width: 100% !important; }
    @media (min-width: 768px) {
      .editor-base img {
        margin: 30px 0; } }
    @media (min-width: 1200px) {
      .editor-base img {
        margin: 45px 0; } }
    .editor-base img:first-of-type {
      margin-top: 0; }
  .editor-base h1 {
    margin-bottom: 15px; }
    @media (min-width: 768px) {
      .editor-base h1 {
        margin-bottom: 20px; } }
    @media (min-width: 768px) {
      .editor-base h1 {
        margin-bottom: 25px; } }
  .editor-base h2 {
    margin-bottom: 12px; }
    @media (min-width: 768px) {
      .editor-base h2 {
        margin-bottom: 16px; } }
    @media (min-width: 768px) {
      .editor-base h2 {
        margin-bottom: 20px; } }
  .editor-base h3 {
    margin-bottom: 5px; }
    @media (min-width: 768px) {
      .editor-base h3 {
        margin-bottom: 10px; } }
    @media (min-width: 768px) {
      .editor-base h3 {
        margin-bottom: 15px; } }

.contact-map {
  margin-top: 40px;
  height: 300px; }
  @media (min-width: 768px) {
    .contact-map {
      height: 350px; } }
  @media (min-width: 992px) {
    .contact-map {
      height: 500px;
      margin-top: 80px; } }

.quick-form {
  margin-top: 40px;
  border: 2px solid #000;
  padding: 15px;
  padding-top: 7px;
  font-size: 16px;
  font-size: 1rem; }
  @media (min-width: 768px) {
    .quick-form {
      margin-top: 0; } }
  .quick-form h2 {
    line-height: 48px;
    display: block;
    border-bottom: 2px solid #000;
    margin-bottom: 0;
    padding-bottom: 5px; }
  .quick-form-item {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -ms-flex-direction: row;
    -ms-flex-wrap: wrap;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    border-bottom: 2px solid #000;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    .quick-form-item:focus-within {
      border-bottom: 2px solid #009641; }
    .quick-form-item label {
      font-size: 16px;
      font-size: 1rem;
      line-height: 50px;
      margin-bottom: 0px;
      -webkit-flex: 0 0 auto;
      -moz-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      -webkit-flex: 0 0 auto;
      flex: 0 0 auto; }
    .quick-form-item .EditingFormControlNestedControl {
      flex: 1; }
    .quick-form-item span {
      -webkit-flex: 1 1 100%;
      -moz-flex: 1 1 100%;
      -ms-flex: 1 1 100%;
      -webkit-flex: 1 1 100%;
      flex: 1 1 100%; }
    .quick-form-item textarea,
    .quick-form-item input[type=text] {
      border: 0px !important;
      width: 100%;
      height: 50px;
      font-size: 16px;
      font-size: 1rem;
      margin: 0 !important;
      text-align: right;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .quick-form-item textarea:focus,
      .quick-form-item input[type=text]:focus {
        text-align: left; }
    .quick-form-item textarea {
      min-height: 242px;
      padding: 15px;
      resize: none;
      text-align: left !important; }
      .quick-form-item textarea:-webkit-resizer {
        display: none; }
    .quick-form-item.selectric-wrapper-top .selectric {
      border-bottom: 0 !important; }
    .quick-form-item.selectric-wrapper-top .selectric-wrapper {
      margin-bottom: 0; }
      .quick-form-item.selectric-wrapper-top .selectric-wrapper:focus .selectric {
        text-align: left; }
    .quick-form-item.selectric-wrapper-top > div {
      margin-top: 8px; }
      .quick-form-item.selectric-wrapper-top > div .selectric-items {
        margin-top: 8px; }
      .quick-form-item.selectric-wrapper-top > div .selectric {
        text-align: right; }
  .quick-form-therms {
    margin-top: 15px;
    font-size: 16px;
    font-size: 1rem; }
    .quick-form-therms input[type="checkbox"] {
      display: none; }
      .quick-form-therms input[type="checkbox"] + label {
        position: relative;
        padding: 0 0 10px 30px;
        margin: 0;
        display: inline-block; }
        .quick-form-therms input[type="checkbox"] + label:before {
          content: "";
          width: 22px;
          height: 22px;
          background: #fff;
          display: block;
          position: absolute;
          left: 0;
          top: 1px;
          border: 3px solid #000;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
      .quick-form-therms input[type="checkbox"]:checked + label:before {
        border: 2px solid #f7403b;
        background-color: #f7403b; }
  .quick-form-submit {
    margin-top: 20px; }
    @media (min-width: 768px) {
      .quick-form-submit {
        margin-top: 30px; } }

@media (min-width: 992px) {
  .column-padding-add {
    padding-left: 30px; } }

@media (min-width: 320px) and (max-width: 991px) {
  .margin-b-xxs-md {
    margin-bottom: 20px; } }

.insert-image {
  text-align: center; }
  .insert-image img {
    max-width: 100%; }

.safety-block {
  margin: 30px 0; }
  .safety-block h3 {
    font-weight: 600; }

.afi-gallery-item {
  padding-left: 5px;
  padding-bottom: 5px;
  flex: 0 1 100%;
  max-width: 100%; }
  @media (min-width: 480px) {
    .afi-gallery-item {
      flex: 0 1 50%;
      max-width: 50%; } }
  @media (min-width: 992px) {
    .afi-gallery-item {
      flex: 0 1 33.333%;
      max-width: 33.333%; } }
  .afi-gallery-item-inner {
    position: relative;
    overflow: hidden;
    display: block; }
    .afi-gallery-item-inner:before {
      content: "";
      display: block;
      padding-top: 80%; }
    .afi-gallery-item-inner img {
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translate(-50%, 0%);
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: top; }

.afi-gallery.large-space .afi-gallery-item {
  padding-bottom: 40px; }

.side-bar-contact {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 16px;
  font-size: 1rem;
  line-height: 22px; }
  @media (min-width: 768px) {
    .side-bar-contact {
      margin-top: 60px;
      margin-bottom: 0; } }
  @media (min-width: 992px) {
    .side-bar-contact {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 24px; } }
  @media (min-width: 1400px) {
    .side-bar-contact {
      margin-top: 80px; } }
  .side-bar-contact h2 {
    margin-bottom: 8px; }
    @media (min-width: 768px) and (max-width: 991px) {
      .side-bar-contact h2 {
        font-size: 24px;
        font-size: 1.5rem; } }

.embed-video.small:before {
  padding-top: 55%; }

.embed-video.small-16:before {
  padding-top: 45%; }

.embed-video {
  position: relative; }
  .embed-video:before {
    content: "";
    display: block;
    padding-top: 65%;
    width: 100%; }

.embed-video.large-16:before {
  padding-top: 60%; }

.embed-video iframe {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.embed-video-overflow.autoplay-1,
.embed-video-overflow.played {
  opacity: 0;
  visibility: hidden;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }

.embed-video-overflow {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -1px;
  background-color: #252525;
  background-size: cover;
  background-position: center;
  cursor: pointer; }
  .embed-video-overflow.in-popup {
    display: block; }
  .embed-video-overflow:focus, .embed-video-overflow:hover {
    border: 0;
    outline: 0; }

.embed-video-icon {
  display: block;
  height: 90px;
  width: 90px;
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }

.embed-video-icon:before {
  content: "";
  display: inline-block;
  font-size: 32px;
  width: 0;
  height: 0;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 30px solid #fff;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  top: 22px;
  left: 35px;
  position: absolute; }

.hp-category .EditorWidgets {
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }
  .hp-category .EditorWidgets .WebPart {
    display: block; }

.fancybox-container * {
  outline: 0 !important; }

.fancybox-infobar__body {
  position: relative !important;
  max-width: -webkit-calc(100% - 132px);
  max-width: -moz-calc(100% - 132px);
  max-width: calc(100% - 132px);
  height: 44px !important;
  padding: 0 !important;
  margin: 0 !important;
  display: inline-block !important;
  background: transparent !important; }
  .fancybox-infobar__body .fancybox-caption {
    border: 0 !important;
    text-align: left;
    background: transparent !important;
    padding: 13px 10px;
    color: white; }

.fancybox-arrow {
  width: 40px !important;
  background-image: none !important;
  margin-left: 5px !important;
  margin-right: 5px !important; }
  @media (min-width: 768px) {
    .fancybox-arrow {
      margin-left: 20px !important;
      margin-right: 20px !important; } }
  .fancybox-arrow:before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    position: relative;
    top: 50%;
    left: 50%;
    display: inline-block !important;
    -ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    color: #fff !important;
    font-size: 40px;
    font-size: 2.5rem;
    width: 40px !important;
    height: 40px !important;
    border-radius: 40px !important;
    border: 1px solid #fff !important;
    background-color: rgba(0, 0, 0, 0.95) !important;
    -moz-transition: all ease-in-out 150ms;
    -o-transition: all ease-in-out 150ms;
    -webkit-transition: all ease-in-out 150ms;
    transition: all ease-in-out 150ms;
    z-index: 9999999; }
  .fancybox-arrow:after {
    background-color: transparent !important;
    background-image: none !important; }

.fancy-video.fancybox-is-open .fancybox-bg {
  opacity: 1 !important;
  background-color: #000 !important; }

.fancy-video .fancybox-toolbar,
.fancy-video .fancybox-navigation {
  height: 50px !important; }

.fancy-video .fancybox-infobar {
  top: auto !important;
  bottom: 0px !important;
  text-align: center !important;
  font-size: 16px;
  font-size: 1rem;
  margin-left: -50px !important;
  left: 50% !important; }

.fancy-video .fancybox-stage {
  position: absolute;
  width: 100%;
  height: auto;
  top: 50px;
  bottom: 50px;
  left: 0;
  right: 0; }

.fancy-video .fancybox-content {
  margin: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  max-height: 100% !important; }

.fancy-video .fancybox-arrow {
  height: auto !important;
  top: auto;
  bottom: 0% !important; }

.fancy-video .fancybox-button--close {
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  top: 5px;
  right: 5px; }
  @media (min-width: 768px) {
    .fancy-video .fancybox-button--close {
      right: 20px; } }

.modal {
  background-color: rgba(0, 0, 0, 0.7);
  color: #000;
  padding-right: 0 !important; }
  .modal .modal-dialog {
    position: relative;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin: 10px auto;
    width: 100%;
    max-width: 1250px; }
    @media (min-width: 768px) {
      .modal .modal-dialog {
        margin-top: 50px; } }
    @media (min-width: 1400px) {
      .modal .modal-dialog {
        margin-top: 100px; } }
    .modal .modal-dialog .modal-content {
      border: 2px solid #000;
      border-radius: 0; }
    .modal .modal-dialog .modal-header .close {
      color: #ff5445;
      position: relative;
      font-size: 0;
      position: absolute;
      top: 15px;
      right: 15px; }
      .modal .modal-dialog .modal-header .close:after {
        content: "\e921";
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 15px;
        font-size: 0.9375rem;
        display: inline-block;
        color: #009641;
        -moz-transition: all ease-in-out 100ms;
        -o-transition: all ease-in-out 100ms;
        -webkit-transition: all ease-in-out 100ms;
        transition: all ease-in-out 100ms;
        cursor: pointer;
        color: #ff5445;
        font-weight: 800; }
      .modal .modal-dialog .modal-header .close:hover:after, .modal .modal-dialog .modal-header .close:focus:after {
        color: #ff2512; }
    .modal .modal-dialog .modal-body {
      text-align: left; }
      .modal .modal-dialog .modal-body .modal-perex {
        margin: 10px 0 30px;
        text-align: left;
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 18px;
        font-weight: 600; }
        @media (min-width: 545px) {
          .modal .modal-dialog .modal-body .modal-perex {
            margin-bottom: 30px; } }
    .modal .modal-dialog .modal-footer {
      display: block;
      margin-top: 10px; }
      .modal .modal-dialog .modal-footer .contact-submit-cont {
        width: 100%;
        margin: 0; }
        .modal .modal-dialog .modal-footer .contact-submit-cont .contact-submit-text {
          font-size: 12px;
          font-size: 0.75rem;
          color: #999;
          text-align: left;
          margin-bottom: 5px; }
          @media (min-width: 545px) {
            .modal .modal-dialog .modal-footer .contact-submit-cont .contact-submit-text {
              margin-bottom: 10px; } }
          @media (min-width: 768px) {
            .modal .modal-dialog .modal-footer .contact-submit-cont .contact-submit-text {
              font-size: 13px;
              font-size: 0.8125rem; } }
          @media (min-width: 992px) {
            .modal .modal-dialog .modal-footer .contact-submit-cont .contact-submit-text {
              margin-bottom: 18px; } }
  .modal .ErrorLabel,
  .modal .EditingFormErrorLabel {
    position: relative;
    display: block;
    width: 100%;
    margin: 20px 0px;
    padding-left: 15px;
    background-color: #009641; }

.field-required:after {
  content: "*";
  color: #ff5445; }

#modal-contact .modal-header {
  padding-bottom: 0px; }
  #modal-contact .modal-header h2 {
    display: block;
    width: 100%;
    border-bottom: 2px solid #000; }

#modal-contact .modal-body {
  padding-top: 0px; }

#modal-sign-up .modal-body {
  text-align: left; }
  #modal-sign-up .modal-body .modal-perex {
    margin: 10px 0 30px;
    text-align: left;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 18px;
    font-weight: 600; }
    @media (min-width: 545px) {
      #modal-sign-up .modal-body .modal-perex {
        margin-bottom: 30px; } }
  #modal-sign-up .modal-body label[id*=AFIUserCountry] {
    display: none; }
  #modal-sign-up .modal-body input[type=text],
  #modal-sign-up .modal-body input[type=password] {
    background-color: #fff;
    width: 100%;
    padding: 8px 10px;
    height: 38px;
    color: #000;
    margin-bottom: 15px;
    border-radius: 0;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-weight: 200;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    border: 0;
    border-bottom: 2px solid #000; }
    #modal-sign-up .modal-body input[type=text]:focus,
    #modal-sign-up .modal-body input[type=password]:focus {
      border-bottom: 2px solid #009641;
      outline: 0; }
  #modal-sign-up .modal-body input[type=submit] {
    margin-top: 20px; }

#modal-sign-up .modal-footer {
  display: block;
  margin-top: 10px; }
  #modal-sign-up .modal-footer .contact-submit-cont {
    width: 100%;
    margin: 0; }
    #modal-sign-up .modal-footer .contact-submit-cont .contact-submit-text {
      font-size: 12px;
      font-size: 0.75rem;
      color: #999;
      text-align: left;
      margin-bottom: 5px; }
      @media (min-width: 545px) {
        #modal-sign-up .modal-footer .contact-submit-cont .contact-submit-text {
          margin-bottom: 10px; } }
      @media (min-width: 768px) {
        #modal-sign-up .modal-footer .contact-submit-cont .contact-submit-text {
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media (min-width: 992px) {
        #modal-sign-up .modal-footer .contact-submit-cont .contact-submit-text {
          margin-bottom: 18px; } }

@media print {
  header,
  footer,
  .box-header-content,
  .content-mobile-menu,
  .products-menu,
  .products-menu-generated,
  .newsletter-box,
  .square-button,
  .square-button-bw,
  .big-button-bw,
  .social-list,
  .big-button-bw-small,
  .content-button-level-up,
  .slick-arrow,
  .product-social-list {
    display: none !important; }
  .main-content {
    width: 100%; }
  .slick-list {
    overflow: visible !important; }
  .slick-track {
    width: 100% !important;
    transform: none !important; }
  .slick-slide {
    display: inline-block !important;
    float: none !important; }
  .content-gallery-list {
    width: 100% !important;
    max-height: none !important; }
  .content-tabs-list,
  .contact-card-content {
    display: block !important; }
  body {
    margin: 0;
    padding: 0;
    font-family: "Times New Roman", Times, serif;
    color: #423f3f !important; }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Arial, Helvetica, sans-serif; }
  h1 {
    font-size: 16pt; }
  h2 {
    font-size: 14pt; }
  h3,
  h4,
  h5,
  h6 {
    font-size: 12pt; }
  p {
    line-height: 1.4;
    font-size: 12pt; }
  a:link,
  a:visited {
    text-decoration: underline;
    font-weight: bold;
    color: #000; }
  img {
    border: 1px solid #000; }
  ul * {
    display: block; } }
