
@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.main-content-product {
		margin-top: 30px;
		@include font-size(14px);
		@include media(md) {
				margin-top: 0;
				@include font-size(16px);
		}
		.title-line {
				border-bottom: 0px;
		}
		h2 {
				border-bottom: 2px solid $color-black;
				margin-top: 30px;
				padding-bottom: 5px;
				margin-bottom: 12px;
				@include media(md) {
						margin-bottom: 16px;
						padding-bottom: 8px;
						@include font-size(28px);
				}
		}
		big,
		h3 {
				display: inline-block;
				@include font-size(18px);
				margin-bottom: 3px;
				@include media(md) {
						@include font-size(22px);
						margin-bottom: 7px;
				}
		}
		h3 {
				font-weight: 600;
		}
}

.text-info-text {
		.row > div:not(:first-of-type) {
				margin-top: 20px;
				@include media(lg) {
						margin-top: 0px;
				}
		}
}

.product-preview {
  @include media(xs) {
    @include flex-block();
    @include flex-flow();
    @include justify-content(space-between);
  }

  &-image {
    width: 100%;

    @include media(xs) {
      width: 40%;
    }

    @include media(md) {
      width: 50%;
    }

    @include media(lg) {
      width: 50%;
    }

    .img-cont {
      padding: 5px;
			position: relative;
      img {
        max-width: 100%;
        max-height: 100%;
        &:not(:first-of-type) {
          display: none;
        }
      }
			.box-product-social {
		position: absolute;
		top: 7px;
		left: 5px;
		.product-social-list {
			position: absolute;
			top: 40px;
			left: -5px;
			width: 60px;
			height: 200px;
			opacity: 0;
			background-color: transparent;
			overflow: hidden;
			@include transition;
			display: none;
			@include flex-flow(column, wrap);
			@include media(xs) {
					top: 35px;
					width: 80px;
					height: 140px;
			}
			@include media(md) {
					width: 80px;
					height: 150px;
			}
			@include media(lg) {
					height: 200px;
			}
			@include media(xxl) {
					height: 300px;
			}
			&.open {
				@include transition;
				opacity: 1;
				@include flex-block;
			}
			&-icon {
				display: block;
				width: 30px;
				height: 30px;
				@include font-size(0px);
				text-decoration: none;
				position: relative;

				@include media(sm) {
					width: 37px;
					height: 37px;
				}

				&:before {
					@include icomoon();
					@include font-size(23px);
					position: absolute;
					top: 50%;
					left: 50%;
					@include translate(-50%, -50%);
					color: $color-primary;
					cursor: pointer;

					@include media(sm) {
						@include font-size(27px);
					}
				}

				&:after {
						content: "";
						position: absolute;
						top: 50%;
						left: 50%;
						@include translate(-50%, -50%);
						color: $color-primary;
						@include transition;
						cursor: pointer;
						width: 30px;
						height: 30px;
						border-radius: 50%;
						border: 1px solid transparent;
						@include media(sm) {
								width: 35px;
								height: 35px;
						}
				}

				&:focus,
				&:hover {
					&:after {
						@include transition;
						border-color: $color-primary;
					}
				}
			}

			@each $name, $icon-number in $share-icon-names {
				.s-#{$name} {
						&:before {
							content: "#{$icon-number}";
						}
					}
			}
		}

		.box-product-social-button {
				position: relative;
				display: inline-block;
				color: inherit;
				@include font-size(0px);
				width: 30px;
				height: 30px;
				color: $color-black;
				cursor: pointer;

				&:before {
					@include icomoon();
					@include font-size(22px);
					@include transition(200ms);
					cursor: pointer;
					content: "\e904";
				}
				&:after {
						content: "";
						position: absolute;
						top: 38%;
						left: 42%;
						@include translate(-50%, -50%);
						color: $color-black;
						cursor: pointer;
						width: 30px;
						height: 30px;
						border-radius: 50%;
						@include media(sm) {
								width: 35px;
								height: 35px;
						}
				}
				&:focus,
				&:hover {
						&:before {
							color: $color-link;
						}
				}
			}

			}
    }
  }

  &-contact {
	    position: relative;
	    @include flex-block();
	    @include flex-flow(column);
    	@include media(xs) {
		      @include calc("width", "60% - 20px");
		      margin-top: 0px;
	    }
	    @include media(xs) {
		      display: block;
	    }
	    @include media(md) {
		      @include calc("width", "50% - 20px");
	    }
	    @include media(lg) {
	      	@include calc("width", "50% - 35px");
	    }
	    &-perex {
		      order: 2;
		      @include font-size(16px);
					line-height: 1.25;
		      margin-top: 20px;
					font-weight: 600;
		      @include media(xs) {
		        	margin-top: 0px;
		      }
					@include media(lg) {
							@include font-size(18px);
					}
	    }
	    &-types {
					display: none;
	    }
	    &-load {
					display: none;
	    }
	    &-colors {
					display: none;
	    }
  }
    .product-contact {
			margin-top: 20px;
      .contact-box-button {
        @include calc("width", "100% - 2px");
        position: absolute;
        bottom: 12px;
        right: 10px;
        width: auto;
        @include font-size (13px);

        @include media (xs) {
          position: static;
          width: auto;
          min-width: 128px;
          @include font-size (14px);
        }

        @include media(lg) {
          min-width: 123px;
          position: absolute;
        }

        @include breakpointhelper (992px, 1024px) {
          min-width: 100px;
          width: 110px;
        }

        @include media(xl) {
          min-width: 155px;
          position: absolute;
        }
      }
    }
  }


.product-technical-info {
  padding: 5px 0 25px;
  margin-top: 15px;

  &-item {
    @include flex-block();
    @include flex-flow();
    border-bottom: 1px solid $color-black;
    margin-bottom: 5px;

    &-name,
    &-value {
      width: 50%;
      background-color: $color-secondary-light;
      padding: 3px 5px;
      @include font-size(13px);

      @include media(md) {
        padding: 3px 10px;
        @include font-size(14px);
      }
    }

    &-value {
      background-color: $color-white;
      text-align: right;
      line-height: 18px;
    }
  }
}

.product-table {
  width: 100%;
  display: block;
  overflow-x: auto;
  margin-top: 15px;

  @include media(sm) {
    display: table;
    overflow-x: hidden;
  }

  &-title {
   background-color: #ffd100;
   font-weight: 600;
  }

  tr:nth-child(2) td {
   font-weight: 600;
  }

  tr td:first-child {
   font-weight: 600;
   border-right: 1px solid #000;
   padding: 2px 3px;
  }

  &-title td {
   border-right: none!important;
  }

 td {
   border: none;
   border-right: 1px solid #dedede;
   border-bottom: 1px solid #000;
  }

  tr td {
   padding: 2px 10px;
   border: 1px solid #dedede;

   @include media(sm) {
     padding: 2px 3px;
   }
  }
}
