@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.products-list {
    .items-container{
        margin-left: -5px;
        margin-right: -5px;
        @include flex-block;
        @include flex-wrap(wrap);
    }
}

.small-space .items-container [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
}

.big-space {
	$space-base: 15px;
	$space-medium: 13px;
	$space: 26px;

  & > .row,
  & > .items-container {
    margin-left: -$space-base;
    margin-right: -$space-base;

    & > .col-md-3,
		& > .col-md-9 {
      padding-left: $space-base;
      padding-right: $space-base;

			@include media(lg) {
				padding-left: $space-medium;
		    padding-right: $space-medium;
			}

			@include media(xl) {
				padding-left: $space;
				padding-right: $space;

				&:last-of-type {
					padding-left: $space+7.5;
				}
			}
    }
  }
}

.small-space {
	$space-base: 5px;

  .row,
  .items-container {
    margin-left: -$space-base;
    margin-right: -$space-base;

    [class*="col-"] {
      padding-left: $space-base;
      padding-right: $space-base;
    }
  }
}

.space-large,
.space-30 {
	$space-base: 30px;

  .row,
  .items-container {
		@include media(lg) {
				margin-left: -15px;
				margin-right: -15px;
		}

    [class*="col-"] {
			@include media(lg) {
					padding-left: $space-base;
					padding-right: $space-base;
			}
    }
  }
}

.space-20 {
	$space-base: 20px;

  .row,
  .items-container {
		@include media(xxl) {
				margin-left: -$space-base;
				margin-right: -$space-base;
		}

    [class*="col-"] {
			@include media(xxl) {
					padding-left: $space-base;
					padding-right: $space-base;
			}
    }
  }
}

.items-container {
		@include flex-block;
		@include flex-flow(row, wrap);
		margin-left: -15px;
		margin-right: -15px;
}

.box-header {
	position: relative;
	max-width: $max-page-width;
	min-height: 90px;
	margin: 0 auto;
	margin-bottom: 10px;
	background-color: $color-primary;
	background-repeat: no-repeat !important ;
	background-position: center center !important ;
	background-size: cover !important ;
	color: $color-white;

	@include media(lg) {
	  min-height: 125px;
		margin-bottom: 35px;
	}

	.box-header-content {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 15px 0 10px;

		.pux-container {
				position: relative;
				padding-right: 80px !important;
				@include media(xl) {
						padding: 0 50px;
				}
		}

		@include media(xs) {
			padding: 25px 0 15px;
		}
		@include media(lg) {
			padding: 25px 0 20px;
		}

		.breadcrumbs {
			color: inherit;
			line-height: 18px;
      padding-right: 80px;

			a,
			span {
				margin-top: -5px;
			}

			.CMSBreadCrumbsCurrentItem {
				color: inherit;
			}

			a {
				color: inherit;
				text-decoration: none;

				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}

		.box-header-title {
			padding-top: 30px;
			font-weight: bold;
			margin-bottom: 0;

			@include media(lg) {
				padding-top: 40px;
			}
			&.h1 {
					font-family: 'Druk-Wide';
					color: #fff;
					font-weight: 600;
					@include font-size-with-line(28px);
					line-height: 36px;

					@include media(md){
							@include font-size-with-line(32px);
							line-height: 38px;
					}
					@include media(lg){
							@include font-size-with-line(36px);
							line-height: 43px;
					}
			}
		}

		.box-header-social-print {
			position: absolute;
			top: -5px;
			right: 15px;
			@include media(md) {
					top: -15px;
			}
			@include media(xl) {
					right: 50px;
			}

			.social-list {
				position: absolute;
				top: -5px;
				right: 65px;
				width: auto;
				height: 30px;
				background-color: $color-black;
				overflow: hidden;
				@include transition(100ms);
				display: none;
				opacity: 0;
				@include media(sm) {
					height: 37px;
					top: -2px;
					right: 80px;
				}

				&.open {
						@include flex-block;
						opacity: 1;
				}

				&-icon {
					display: inline-block;
					float: left;
					width: 30px;
					height: 30px;
					@include font-size(0px);
					text-decoration: none;
					position: relative;

					@include media(md) {
						width: 37px;
						height: 37px;
					}

					&:before {
						@include icomoon();
						@include font-size(23px);
						position: absolute;
						top: 50%;
						left: 50%;
						@include translate(-50%, -50%);
						color: $color-white;
						@include transition(100ms);
            cursor: pointer;

						@include media(sm) {
							@include font-size(27px);
						}
					}

					&:focus,
					&:hover {
						&:before {
							@include transition(100ms);
							color: $color-link;
						}
					}
				}

				@each $name, $icon-number in $share-icon-names {
					.s-#{$name} {
							&:before {
								content: "#{$icon-number}";
							}
						}
				}
			}

			.box-header-social,
			.box-header-print {
				display: inline-block;
				color: inherit;
				@include font-size(0px);
				width: 25px;
				height: 25px;
				@include media(sm) {
						width: 35px;
						height: 35px;
				}
				&:after {
					@include icomoon();
  			  @include font-size(20px);
					@include transition(100ms);
        	cursor: pointer;
					@include media(sm) {
							@include font-size(33px);
					}
				}

				&:focus,
				&:hover {
					&:after {
							@include transition(100ms);
							color: $color-link;
					}
				}
			}

			.box-header-social {
				&:after {
						content: "\e904";
				}
			}

			.box-header-print {
				&:after {
						content: "\e902";
				}
			}
		}
	}
}

.main-content {
	width: 100%;
	margin-top: 20px;

	@include media (md) {
		margin-top: 0px;
	}
  h1,
  h2 {
    padding-bottom: 5px;
    border-bottom: 2px solid $color-black;
    margin-bottom: 15px;
  }
  .insert-image {
		max-width: 100%;

    img {
		  max-width: 100%;
    }
	}
	.content-text {
		margin-bottom: 20px;
	}
  a {
    text-decoration: none;
    color: $color-black;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.product-video {
		&-holder {
				position: relative;
				margin-bottom: 20px;
				margin-top: 15px;
				@include media(md) {
						margin-top: 0;
						margin-bottom: 30px;
				}
				&:before {
						content: "";
						display: block;
						padding-bottom: 56.3%;
				}
				iframe {
						position: absolute;
						display: block;
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
				}
		}
		&-text {
				margin-bottom: 20px;
				@include media(md) {
						margin-bottom: 30px;
				}
		}
}

.content-button-level-up {
  position: relative;
  display: inline-block;
  padding-left: 35px;
  margin-top: 15px;
  margin-bottom: 25px;
  line-height: 20px;
  @include font-size(14px);

  @include media(lg) {
    padding-left: 45px;
    @include font-size(18px);
    font-weight: 600;
  }

  &:hover,
  &:focus {
    color:  $color-black;
    text-decoration: none !important;

    &:before {
			color: #fff;
      background-color: $color-link;
			border: 1px solid $color-link;
    }
  }

  &:before {
    @include icomoon;
    @include pseudo-elements("\e90d", absolute, 50%, 0px);
    display: inline-block;
    @include translate(0%, -50%);
    font-weight: 500;
    color: $color-black;
    @include font-size(28px);
    width: 28px;
    height: 28px;
    border-radius: 28px;
    text-align: center;
    line-height: 26px;
    border: 1px solid $color-black;
    @include transition(150ms);

    @include media(lg) {
      @include font-size(35px);
      width: 35px;
      height: 35px;
      border-radius: 35px;
      text-align: center;
      line-height: 32px;
    }
  }
}

.content-mobile-menu {
	display: none;
  @include font-size(16px);

	&:hover {
		color: $color-base !important;
		background: $btn-bg;
		border: 2px solid $color-black !important;
	}

	&:after {
		display: inline-block;
		vertical-align: middle;
    @include icomoon();
		content: "\e903";
		margin-left: 5px;
		margin-top: -3px;
    @include font-size(35px);
		@include animate(transform);
	}

	&.active{
		&:after{
			@include rotate(180deg);
		}
	}

	@include media(xxs, sm){
		display: block;
	}
}

.content-menu,
.content-menu-generated {
  width: 107%;
  @include list-reset();
  margin-top: 20px;
  color: $color-black;
  @include media(md) {
    display: block !important;
  }
  @include media(md) {
    margin-top: 0px;
  }
	ul {
			@include list-reset();
	}
  .content-menu-item,
	li {
    & > a {
      display: inline-block;
      text-decoration: none;
      @include font-size(20px);
      line-height: 24px;
      color: inherit;
      font-weight: 400;
      padding: 5px 5px;
			@include transition;
      @include media(md) {
	        @include font-size(22px);
					line-height: 28px;
      }
      @include media(lg) {
	        @include font-size(24px);
					line-height: 30px;
	        padding: 2px 0px;
      }
      @include media(xl) {
					@include font-size(28px);
	      	line-height: 35px;
      }
      &:hover,
      &:focus {
	        color: $color-link;
      }
    }

    &.active > a {
      color: $color-link;
    }

		&.active .content-submenu {
			display: block;
    }

    .content-submenu {
      width: 100%;
      @include list-reset();
      padding: 0 7px;
      margin-bottom: 10px;
      text-decoration: none;
			display: none;
			@include media(lg) {
					padding: 0 2px;
			}
      .content-submenu-item {
        & > a {
          position: relative;
          display: block;
          padding: 5px 0;
          color: $color-black;
          @include font-size(16px);
          line-height: 20px;
					font-weight: 600;
          text-decoration: none;

          @include media(md) {
            padding: 2px 0;
            @include font-size(13px);
          }

          @include media(lg) {
            @include font-size(16px);
          }

          @include media(xl) {
            @include font-size(18px);
            line-height: 30px;
          }
          &:hover,
          &:focus {
              color: $color-link;
          }
        }

        .active {
          	color: $color-link;
        }
      }
    }
  }
}

.content-list {
		.row {
				margin-left: -15px;
				margin-right: -15px;
				[class*="col-"] {
						padding-left: 15px;
						padding-right: 15px;
						@include media(lg) {
								padding-left: 20px;
								padding-right: 20px;
						}
				}
		}
}

.button-show-content,
.arrow-down-link {
  margin-top: 20px;
  margin-bottom: 30px;
}

.content-gallery {
	margin: 30px 0;
	position: relative;
	max-width: 100%;
	overflow: hidden;
	@include media(md) {
			margin: 45px 0;
	}
  &-list {
		margin: 0 -25px;
    &-item {
      width: auto;
      height: 140px !important;
			margin: 0 25px;
			position: relative;
			overflow: hidden;
			text-align: center;
      img {
        width: auto;
        height: 140px;
				display: inline-block !important;
				@include media(xs) {
						@include cover-image;
						display: block !important;
				}
      }
    }
  }

  &-arrow-left,
  &-arrow-right {
		position: absolute;
		top: 50%;
    width: 40px;
		height: 40px;
		transform: translateY(-50%);
    @include font-size(0px);
    cursor: pointer;
		z-index: 99;
    &:before {
      @include icomoon;
      content: "\e90d";
			position: relative;
      display: inline-block;
      color: $color-black;
			font-weight: 600;
      @include font-size(40px);
      width: 40px;
      height: 40px;
      border-radius: 40px;
      border: 2px solid $color-black;
      @include transition(150ms);
			@include flex-block;
			@include justify-content(center);
			@include align-items(center);
			@include media(xs) {
					color: $color-white;
					border: 2px solid $color-white;
			}
    }
    &:focus,
    &:hover {
      &:before {
					color: $color-link;
					border-color: $color-link;
					@include media(xs) {
							box-shadow: 0 0 5px $color-black;
					}
      }
    }
  }
	&-arrow-left {
			left: 10px;
  }
  &-arrow-right {
			right: 10px;
      &:before {
        content: "\e908";
      }
  }
}

.gallery {
		&-wrapper {
				margin-top: 10px;
				@include media(md) {
						margin-top: 15px;
				}
		}
		&-card {
				display: block;
				margin-bottom: 20px;
				@include transition;
				@include media(md) {
						margin-bottom: 30px;
				}
				&-img {
						position: relative;
						overflow: hidden;
						&:before {
								content: "";
								display: block;
								padding-top: 65%;
						}
						img {
								@include cover-image;
						}
				}
				&.video {
						.gallery-card-img {
								&:after {
										content: "\e906";
										@include icomoon;
										position: absolute;
										top: 0;
										left: 0;
										width: 100%;
										@include font-size(40px);
										height: 100%;
										@include flex-block;
										@include justify-content(center);
										@include align-items(center);
										@include transition;
										color: $color-primary;
										@include media(lg) {
												@include font-size(60px);
										}
								}
						}
				}
				&-title {
						@include font-size(22px);
						margin-top: 5px;
						@include media(lg) {
								@include font-size(28px);
								margin-top: 10px;
						}
				}
				&:hover,
				&:focus {
						outline: 0;
						border: 0;
						&.video {
								color: $color-black;
								.gallery-card-img {
										&:after {
												color: $color-link;
										}
								}
						}
				}
		}
}

.card-list {
		&-wrapper {
				margin-top: 10px;
				@include media(md) {
						margin-top: 15px;
				}
		}
		&-item {
				display: block;
				position: relative;
				margin-bottom: 20px;
				@include transition;
				@include calc("height", "100% - 20px");
				@include media(sm) {
						padding-bottom: 40px;
				}
				@include media(md) {
						padding-bottom: 50px;
						margin-bottom: 30px;
						@include calc("height", "100% - 30px");
				}
				&-img {
						position: relative;
						overflow: hidden;
						&:before {
								content: "";
								display: block;
								padding-top: 65%;
						}
						img {
								@include cover-image;
								height: auto;
								width: 100%;
						}
				}
				&-title {
						@include font-size(22px);
						margin-top: 7px;
						line-height: 1.15;
						@include media(lg) {
								@include font-size(28px);
								margin-top: 12px;
						}
				}
				&-perex {
						@include font-size(14px);
						font-weight: 600;
						line-height: 1.25;
						@include media(md) {
							  @include font-size(18px);
						}
				}
				&-bottom {
						width: 100%;
						@include flex-block;
						@include justify-content(space-between);
						@include align-items(center);
						margin-top: 10px;
						@include font-size(14px);
						@include media(sm) {
								position: absolute;
								bottom: 0;
								left: 0;
						}
						@include media(lg) {
								margin-top: 15px;
								@include font-size(18px);
						}
						&:after {
								content: "\e908";
								@include icomoon;
								display: inline-block;
								@include font-size(25px);
								color: #000;
								font-weight: 600;
								border: 2px solid #000;
								@include transition;
								border-radius: 100%;
								@include media(md) {
										@include font-size(30px);
								}
								@include media(lg) {
										@include font-size(35px);
								}
						}
				}
				&.product,
				&.hp-news {
						padding-bottom: 30px;
						margin-bottom: 35px;
						@include calc("height", "100% - 30px");
						@include media(md) {
								padding-bottom: 45px;
								margin-bottom: 50px;
								@include calc("height", "100% - 50px");
						}
						&:after {
								content: "\e908";
								@include icomoon;
								display: inline-block;
								position: absolute;
								bottom: 0;
								right: 0;
								@include font-size(25px);
								color: #000;
								font-weight: 600;
								border: 2px solid #000;
								@include transition;
								border-radius: 100%;
								@include media(md) {
										@include font-size(30px);
								}
								@include media(lg) {
										@include font-size(35px);
								}
						}
				}
				&.hp-news {
						.card-list-item-img {
								&:before {
										padding-top: 50%;
								}
						}
				}
				&.line {
						.card-list-item-title {
								border-bottom: 1px solid #000;
								padding-bottom: 10px;
								@include media(md) {
										border-bottom: 2px solid #000;
										padding-bottom: 10px;
								}
						}
				}
				&.slim-image {
						.card-list-item-img {
								&:before {
										padding-top: 55%;
								}
						}
				}
				&.quick-info {
						padding: 10px 0;
						.card-list-item-img {
								&:before {
										padding-top: 45%;
										@include media(md) {
												padding-top: 65%;
										}
										@include media(xl) {
												padding-top: 45%;
										}
								}
						}
						.card-list-item-perex {
								font-weight: 400;
						}
						.card-list-item-bottom {
								&:after {
										display: none;
								}
						}
				}
				&:hover:not(.quick-info),
				&:focus:not(.quick-info) {
						outline: 0;
						border: 0;
						color: #000;
						&.product,
						&.hp-news {
								&:after {
										color: #fff;
										background-color: $color-link;
										border: 2px solid $color-link;
								}
						}
						.card-list-item-bottom {
								&:after {
										color: #fff;
										background-color: $color-link;
										border: 2px solid $color-link;
								}
						}
				}
		}
}

.full-width {

 &-item {
    @include flex-block;
    padding: 10px 0;
    margin: 0;
    border-bottom: 1px solid $color-black;
    min-height: 91px;
    position: relative;
    @include animate("background-color, color");

    @include media(xs) {
      min-height: 155px;
      padding: 10px 10px;
    }

    @include media(md) {
      min-height: 184px;
    }

    &:hover,
    &:focus {
      @include media(lg) {
        background-color: $color-link;
      }

      .full-width-item-content {
        &:before {
         background-color: $color-black;
        }

        &:after {
          @include media(lg) {
            color: $color-white;
          }
        }

        &-button {
          @include media(lg) {
            color: $color-primary !important;
            background: $color-black !important;
            text-decoration: none !important;
          }
        }
      }
    }

    &-img {
      width: auto;
      @include flex(0 0 100px);
      position: relative;

      @include media(xs) {
        @include flex(0 0 160px);
      }

      @include media(md) {
        @include flex(0 0 225px);
      }

      img {
        position: relative;
        max-width: 100%;
        max-height: 100%;

        @include media(xs) {
          top: 50%;
          @include translate(0, -50%);
        }
      }
    }

    &-content {
      position: relative;
      @include flex(1 1 auto);
      padding-left: 10px;
      text-align: left;
      padding-right: 30px;

      @include media(xs) {
        padding-right: 0px;
      }

      @include media(md) {
        padding-left: 20px;
      }

      &-title {
        @include font-size(15px);
        border-bottom: 0;

        @include media(xxs, xxs) {
          line-height: 19px;
          font-weight: 800;
          text-decoration: underline;
        }

        @include media(xs) {
          border-bottom: 2px solid $color-black;
        }

        @include media(sm) {
          @include font-size(18px);
          padding-bottom: 5px;
        }

        @include media(md) {
          @include font-size(20px);
        }

        @include media(xl) {
          @include font-size(22px);
        }
      }

      &-perex {
        @include media(xs) {
          padding-bottom: 25px;
        }
      }

      &:before {
        content: "";
        position: absolute;
        display: none;
        bottom: 2px;
        left: 17px;
        width: 27px;
        height: 27px;
        @include border-radius(27px);
        background-color: transparent;
        @include transition(100ms);

        @include media(lg) {
          display: block;
        }
      }

      &:after {
        content: "\e908";
        position: absolute;
        display: block;
        top: 0px;
        right: 0px;
        @include font-size(25px);
        @include transition(100ms);

        @include media(xs) {
          top: auto;
          bottom: 0px;
          left: 5px;
          @include font-size(30px);
        }

        @include media(md) {
          bottom: 0px;
          left: 15px;
        }

        @include media(sm, md) {
          color: $color-black !important;
        }
      }
    }
  }
}


.content-tabs {
  margin-top: 25px;

  &-title {
    @include flex-block();
		border-bottom: 2px solid $color-black !important;

    &-link {
			@include flex(0 1 auto);
      display: inline-block;
      margin-bottom: -5px;
      padding: 2px 20px 2px 0;
      @include font-size(14px);
      color: $color-black;
      text-decoration: none;
      text-transform: capitalize;
      font-weight: 600;
      @include media(sm) {
        padding: 5px 30px 5px 0;
        @include font-size(16px);
      }
      @include media(md) {
				padding: 8px 40px 8px 0;
        @include font-size(18px);
      }

       &.active,
       &:focus,
       &:hover {
         color: $color-link;
         text-decoration: none !important;
       }
    }
  }

  &-list {
    &-item {
      display: inline-block;
      width: 100%;
      height: 50px;
      padding-top: 10px;
      text-decoration: none;

      &:focus,
      &:hover {
        text-decoration: underline;
        color: $color-black;
      }

      &-image {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 65%;
        border: 1px solid $color-black;
        overflow: hidden;

        img {
          @include cover-image;
        }
      }

      &-name {
        margin-top: 5px;
        @include font-size(11px);
        color: $color-black;

        @include media(xs) {
          @include font-size(13px);
        }

        @include media(md) {
          margin-top: 10px;
        }

        @include media(xl) {
          @include font-size(14px);
        }
      }
    }
		.row {
				margin-left: -15px;
				margin-right: -15px;
				[class*="col-"] {
						padding-left: 13px;
						padding-right: 13px;
				}
		}
  }
}

.load-more-products {
  display: none;
}

.contact-box {
  padding: 10px;
  position: relative;
	border: 2px solid $color-black;

  @include media(md) {
    padding: 7px;
  }

  @include media(lg) {
    padding: 12px;
  }
	h2 {
		 @include font-size(18px);
		 line-height: normal;
		 padding-bottom: 5px;
		 border-bottom: 2px solid $color-black;
		 margin-bottom: 5px;
		 margin-top: 0px !important;
		 @include media(lg) {
			 		@include font-size(22px);
					padding-bottom: 7px;
					line-height: normal;
					margin-bottom: 5px;
		 }
	}
  &-text {
    @include font-size(12px);
    font-weight: bold;
		min-height: 50px;
    @include media(xs, sm) {
      @include font-size(14px);
			min-height: 0;
    }
    @include media(md, md) {
      @include font-size(13px);
			min-height: 0;
    }
		@include media(lg) {
				min-height: 50px;
		}
    table {
      border-collapse: collapse;
			font-weight: 400;
      td {
	        padding-right: 5px;
					@include media(xs) {
						@include font-size(16px);
					}
					@include media(md) {
						@include font-size(13px);
					}

					@include breakpointhelper (768px, 850px) {
						@include font-size(11px);
					}

					@include media(lg) {
						@include font-size(15px);
					}
					@include media(xxl) {
						@include font-size(16px);
					}
      }
      a {
          color: $color-black;
          text-decoration: none;
					cursor: pointer;
          &:hover,
          &:focus {
            color: $color-black;
            text-decoration: none;
          }
        }

    }
  }

  .contact-box-button {
    @include calc("width", "100% - 2px");
    position: absolute;
    bottom: 12px;
    right: 12px;
    width: auto;
    @include font-size (13px);
		cursor: pointer;
    @include media (xs) {
      right: 27px;
      min-width: 128px;
      @include font-size (14px);
    }

    @include media (md) {
      position: static;
    }

    @include media(lg) {
      min-width: 155px;
    }
  }
}

.about-us-peoples {
  &-title {
    background-color: $color-secondary-light;
    padding: 5px 10px;
    margin: 0;
    border-bottom: 0;
  }

  &-item {
    margin-bottom: 30px;
    line-height: 18px;
    @include font-size(13px);

    @include media(lg) {
      @include font-size(14px);
    }

    &-title {
      @include font-size(14px);
      margin-bottom: 3px;

      @include media(md) {
        @include font-size(16px);
        margin-bottom: 5px;
      }

      @include media(lg) {
        @include font-size(18px);
      }
    }

    &-image {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 67.5%;
      margin-bottom: 5px;
      background-color: $color-primary;
      overflow: hidden;

      @include media(md) {
        margin-bottom: 15px;
        padding-bottom: 105%;
      }

      img {
        position: absolute;
        bottom: 0;
        left: 50%;
        @include translate(-50%, 0);
        width: auto;
        height: 100%;

        @include media(md) {
          width: 100%;
          height: auto;
        }
      }

    }
  }
}

.events-tabs {
  .content-tabs-title-link {
    background-color: transparent;
    text-transform: none;

    @include media(xxs, xxs) {
      @include font-size(12px);
    }
    &:focus,
    &:hover {
      color: $color-link;
    }

    &.active {
      color: $color-link;
    }
  }

  .content-tabs-list-cont {
    padding-top: 10px;

    @include media(md) {
      padding-top: 25px;
    }
  }

  &-item {
    margin-bottom: 10px;
    @include flex-block;
    background-color: $color-secondary-light;
    &-image {
      width: 40%;
      min-height: 95px;
      padding: 5px;
      text-align: center;

      @include media(xs) {
        min-height: 135px;
        padding: 15px 12.5px;
      }

      @include media(md) {
        padding: 25px 15.5px 25px 19.5px;
      }

      @include media(lg) {
        width: 27.5%;
        min-height: 168px;
      }

      img {
        @include vertical-pos(50%);
        max-width: 100%;
        max-height: 100%;
        margin: 0px !important;
      }
    }

    &-text {
      position: relative;
      width: 75%;
      padding: 5px 5px 35px 5px;
      @include font-size(13px);
      line-height: 15px;

      @include media(sm) {
        padding: 10px;
        padding-bottom: 50px;
        @include font-size(14px);
        line-height: 18px;
      }

      &-title {
        @include font-size(14px);
        font-weight: bold;
        line-height: 18px;

        @include media(xs) {
          @include font-size(16px);
          line-height: 25px;
        }

        @include media(lg) {
          @include font-size(18px);
          line-height: 35px;
        }
      }

      &-date {
        position: absolute;
        bottom: 5px;
        right: 5px;
        @include font-size(13px);
        font-weight: 600;

        @include media(xs) {
          bottom: 20px;
          right: 15px;
          @include font-size(14px);
        }

        @include media(lg) {
          @include font-size(18px);
        }
      }
    }
  }
}

.newsletter-box {
  background-color: $color-secondary-light;
  padding: 9px 10px;
  margin-bottom: 25px;

  @include media(lg) {
    padding: 9px 18px;
  }

  .form-horizontal {
    position: relative;
    width: 100%;

    @include media(sm) {
        @include flex-inline;
    }
  }

  .form-group {
    margin: 0;

    @include media(sm) {
      @include flex-inline;
      @include calc("width", "100% - 120px");
    }
  }

  .editing-form-label-cell {
    display: inline-block;
    width: 100%;
    @include font-size(14px);
    line-height: 24px;

    @include media(xs) {
      @include font-size(17px);
    }

    @include media(md) {
      width: 60%;
    }

    @include media(lg) {
      @include font-size(22px);
    }

    .control-label {
      margin: 0px;
      padding-bottom: 5px;

      @include media(sm) {
        padding-bottom: 0px;
        padding-top: 5px;
      }
    }
  }

  .editing-form-value-cell {
    display: inline-block;
    @include calc("width", "100% - 100px");

    @include media(xs) {
      @include calc("width", "100% - 120px");
    }

    @include media(md) {
      width: 40%;
      padding-right: 5px;
    }

    .form-control {
      display: inline;
      @include border-radius(0);
      height: 32px;
      border: 1px solid $color-white;
      @include transition();
      outline: 0;

      &:focus {
        border-color: $color-black;
      }
    }
  }

  .form-group-submit {
    position: absolute;
    display: inline-block;
    width: 120px;
    text-align: right;
    bottom: 0;
    right: 0;


    @include media(sm) {
      position: static;
      width: 120px;
      padding-left: 5px;
    }

    .SubscriptionButton {
      display: inline-block;
      width: 90px;
      border: 1px solid $color-black;
      color: $color-black;
      background-color: transparent;
      @include font-size(15px);
      line-height: 20px;
      font-weight: 600;
      padding: 5px 0;
      @include animate("background-color, color");
      cursor: pointer;
      outline: 0;

      @include media(xs) {
        width: 110px;
      }

      @include media(md) {
        width: 100%;
      }

      @include media(md) {
        @include font-size(16px);
      }

      @include media(lg) {
        @include font-size(14px);
      }

      &:focus,
      &:hover {
        background-color: $color-black;
        color: $color-white;
      }
    }
  }

  .ErrorMessage {
    display: block;
    background-color: $color-primary;
    margin-bottom: 10px;
    font-weight: 600;
  }
}

.faq-tabs {
  @include media(sm) {
    margin-top: 10px;
  }

  @include media(lg) {
    margin-top: 25px;
  }

  &-item {
    background-color: $color-secondary-light;
    @include transition();
    margin-bottom: 10px;

    &-link {
      padding: 10px;
      cursor: pointer;
      @include transition();

      @include media(sm) {
        padding: 15px;
      }

      @include media(lg) {
        padding: 17px 20px;
      }

      h2 {
        margin: 0px;
        @include font-size(16px);
        line-height: 22px;

        @include media(xs) {
          font-weight: 800;
        }

        @include media(sm) {
          @include font-size(18px);
          line-height: 27px;
        }

        @include media(lg) {
          @include font-size(22px);
          line-height: 35px;
        }
      }

      &.active {
        h2 {
          background-image: linear-gradient(#000 2px, transparent 2px);
          background-size: 100% 22px;
          background-position: 0 20px;

          @include media(sm) {
            background-size: 100% 27px;
            background-position: 0 25px;
          }

          @include media(lg) {
            background-size: 100% 35px;
            background-position: 0 33px;
          }
        }
        &:focus,
        &:hover {
          background-color: transparent;
        }
      }
    }

    &-content {
      display: none;
      padding: 0 10px 10px 10px;
      @include font-size(14px);
      line-height: 18px;

      @include media(sm) {
        padding: 0 15px 15px 15px;
      }

      @include media(lg) {
        padding: 0 20px 17px 20px;
      }

      a {
        color: blue;
        text-decoration: underline;

        &:focus,
        &:hover {
          color: blue;
          text-decoration: none;
        }
      }
    }

    &:focus,
    &:hover {
      background-color: $color-secondary;
    }

    &.active {
      background-color: $color-primary;

      &:focus,
      &:hover {
        background-color: $color-primary;
      }
    }

  }
}

.locked-box {
  @include flex-block();
  @include align-items(center);
  @include justify-content(space-between);
  background-color: $color-secondary-light;
  padding: 5px 10px;
  margin-top: 45px;
  margin-bottom: 35px;

  @include media(lg) {
    padding: 5px 15px;
  }

  &-text {
    position: relative;
    padding-left: 30px;
    @include font-size(15px);
    line-height: 17px;

    @include media(xs) {
      @include font-size(17px);
      line-height: 19px;
    }

    @include media(sm) {
      @include font-size(19px);
      line-height: 21px;
    }

    @include media(md, md) {
      padding-left: 40px;
      @include font-size(17px);
      line-height: 19px;
    }

    @include media(xl) {
      @include font-size(22px);
      line-height: 25px;
    }

    &:before {
      @include icomoon;
      @include pseudo-elements("\e90a", absolute, 50%, -5px);
      @include font-size(20px);
      @include translate( 0, -50%);
      color:  $color-black;

      @include media(sm) {
        @include font-size(22px);
      }

      @include media(xl) {
        @include font-size(25px);
      }
    }
  }

  &-submit {
    @include flex(0 0 90px);
    padding-left: 10px;

    @include media(sm) {
      @include flex-basis(110px);
    }

    @include media(lg) {
      @include flex-basis(160px);
    }

    .button-sign-up {
      width: 100%;
      min-height: 32px;
      @include font-size(14px);
      line-height: 16px;
      padding: 8px 5px;

    }
  }
}

.download-cont {
  padding-bottom: 30px;
}

.download-item {
  position: relative;
  @include flex-block();
  @include flex-direction(column);
  @include justify-content(center);
  width: 100%;
  min-height: 40px;
  padding: 5px 0px 5px 30px;
  @include transition();
  color: $color-black;
  text-decoration: none;
  margin-bottom: 15px;
  margin-top: 0px;
	padding-left: 37px;
	@include font-size(14px);
  @include media(md) {
    padding-left: 37px;
		margin-bottom: 25px;
		@include font-size(16px);
  }
  &:hover,
  &:focus {
	    color:  $color-link;
	    text-decoration: none !important;
  }
  &:before {
    @include icomoon;
    @include pseudo-elements("\e91a", absolute, 19px, 0);
    @include font-size(20px);
    @include translate( 0, -50%);
    color: inherit;
  }

  h3 {
    @include font-size(16px);
    margin: 0;
    padding: 0;
    font-weight: 400;
		line-height: normal;
    @include media(md) {
      @include font-size(18px);
    }
  }
}

.download-small-cont {
  padding-bottom: 30px;
  margin-top: 20px;

  @include media(lg) {
    margin-top: 40px;
  }

  .product-card-title {
    margin-bottom: 5px;
  }

  .download-item {
    margin-top: 10px;
    margin-bottom: 0px;
  }
}

.subscribed {
  .locked-box {
    display: none;
  }
}

.unsubscribed {
  .download-item.locked {
    &:before {
      content: "\e90a";
    }
  }
}

.big-gallery-item {
  display: block;
  @include font-size(12px);
  line-height: 15px;
  margin-bottom: 10px;
	margin-top: 10px;
	font-weight: 600;
  @include media(sm) {
    	@include font-size(14px);
    	line-height: 18px;
  }
	@include media(md) {
			margin-top: 20px;
			@include font-size(15px);
	}
  &:hover,
  &:focus {
    text-decoration: none !important;
  }

  &-image {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0px;
    padding-bottom: 82%;
    margin-bottom: 5px;

    img {
      @include cover-image;
    }
  }
}

.logos-gallery-item {
  display: block;
  @include font-size(14px);
  line-height: 18px;
  margin-bottom: 20px;
  font-weight: 600;

  &-image {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 10px;

    @include media(md) {
      margin-bottom: 25px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &-links {
    font-weight: 400;

    a {
      color: $color-primary;
      text-decoration: underline;
      display: inline-block;
      margin-top: 10px;

      @include media(md) {
        margin-top: 0px;
      }

      &:focus,
      &:hover {
        color: $color-primary;
        text-decoration: none;
      }
    }
  }
}

.social-media {
  &-card {
    &:not(:last-of-type){
      margin-bottom: 20px;

      @include media(lg) {
        margin-bottom: 0px;
      }
    }

    &-title {
      padding: 5px 10px;
      border-bottom: 1px solid $color-secondary-light;
      @include font-size(16px);
      line-height: 20px;
      font-weight: 600;
      margin-bottom: 0px;

      @include media(lg) {
        @include font-size(18px);
        line-height: 22px;
        padding: 15px 10px;
      }
    }

    &-content {
      display: block;

      @include media(xs) {
        @include flex-block;
        @include align-items(center);
      }

      @include media(xs, xs) {
        @include flex-wrap(wrap);
        flex-wrap: wrap;
      }

      &-icon {
        position: relative;
        text-align: center;
        padding: 15px 10px;
        margin-top: 10px;
        font-size: 0;
        min-height: 60px;

        @include media(xs) {
          @include flex(0 0 100%);
        }

        @include media(sm) {
          @include flex(0 0 65px);
          margin-top: 0px;
        }

        &:before {
          @include icomoon;
          @include pseudo-elements("", absolute, 50%, 50%);
          @include translate(-50%, -50%);
          @include font-size(40px);
          color: $color-primary;

          @include media(sm) {
            @include font-size(45px);
          }
        }
      }

      @each $name, $icon-number in $share-icon-names {
        .s-#{$name} {
          &:before {
            content: "#{$icon-number}";
          }
        }
      }

      &-text-first,
      &-text-second {
        text-align: left;
        padding: 15px 10px;
        @include font-size(15px);
        line-height: 18px;

        @include media(xs, xs) {
          @include flex(0 0 50%);
        }

        @include media(sm) {
          @include flex(0 1 55%);
        }

        h3,
        h4 {
          @include font-size(15px);
          line-height: 18px;
          font-weight: 600;
          margin: 0;
        }
      }

      &-text-second {
        @include media(sm) {
          @include flex(0 1 45%);
        }
      }
    }

    &-join {
      padding: 15px 10px;
      text-align: right;
      border-bottom: 1px solid $color-secondary-light;

      a {
        position: relative;
        @include font-size(20px);
        line-height: 30px;
        color: $color-primary;
        padding-right: 40px;
        font-weight: 600;

        &:hover,
        &:focus {
          text-decoration: none;
        }

        &:after {
          @include icomoon();
          @include font-size(30px);
          content: "\e907";
          font-weight: 600;
          position: absolute;
          top: 50%;
          right: 0;
          @include translate(0, -50%);
          color: $color-primary;
          cursor: pointer;
        }
      }
    }
  }
}

.info-box {
  background-color: $color-primary;
  margin-top: 40px;
  @include font-size(16px);
  line-height: 20px;
  font-weight: 800;
  padding: 7px 15px;
  display: inline-block;

  @include media(md) {
    display: block;
  }

  @include media(lg) {
    @include font-size(18px);
    line-height: 23px;
  }
}

.editor-base {
		font-family: 'GT-America';
		@include font-size(16px);
		line-height: 22px;
		@include media(lg) {
				@include font-size(18px);
				line-height: 24px;
		}
		img {
				margin: 20px 0;
				max-width: 100% !important;
				@include media(md) {
						margin: 30px 0;
				}
				@include media(xl) {
						margin: 45px 0;
				}
				&:first-of-type {
						margin-top: 0;
				}
		}
		h1 {
				margin-bottom: 15px;
				@include media(md) {
						margin-bottom: 20px;
				}
				@include media(md) {
						margin-bottom: 25px;
				}
		}
		h2 {
				margin-bottom: 12px;
				@include media(md) {
						margin-bottom: 16px;
				}
				@include media(md) {
						margin-bottom: 20px;
				}
		}
		h3 {
				margin-bottom: 5px;
				@include media(md) {
						margin-bottom: 10px;
				}
				@include media(md) {
						margin-bottom: 15px;
				}
		}

}

.contact-map {
		margin-top: 40px;
		height: 300px;
		@include media(md) {
				height: 350px;
		}
		@include media(lg) {
				height: 500px;
				margin-top: 80px;
		}
}

.quick-form {
		margin-top: 40px;
		border: 2px solid #000;
		padding: 15px;
		padding-top: 7px;
		@include font-size(16px);
		@include media(md) {
				margin-top: 0;
		}
		h2 {
				line-height: 48px;
				display: block;
				border-bottom: 2px solid #000;
				margin-bottom: 0;
				padding-bottom: 5px;
		}
		&-item {
				@include flex-block;
				@include flex-flow(row, wrap);
				border-bottom: 2px solid #000;
				@include transition;
				&:focus-within {
						border-bottom: 2px solid $color-primary;
				}
				label {
						@include font-size(16px);
						line-height: 50px;
						margin-bottom: 0px;
						@include flex(0 0 auto);
				}
				.EditingFormControlNestedControl {
						flex: 1;
				}
				span {
						@include flex(1 1 100%);
				}
				textarea,
				input[type=text] {
						border: 0px !important;
						width: 100%;
						height: 50px;
						@include font-size(16px);
						margin: 0 !important;
						text-align: right;
						@include transition;
						&:focus {
								text-align: left;
						}
				}
				textarea {
						min-height: 242px;
						padding: 15px;
						resize: none;
						text-align: left !important;
						&:-webkit-resizer {
								display: none;
						}
				}
				&.selectric-wrapper-top {
						.selectric {
							border-bottom: 0 !important
						}
						.selectric-wrapper {
								margin-bottom: 0;
								&:focus {
										.selectric {
												text-align: left;
										}
								}
						}
						& > div {
								margin-top: 8px;
								.selectric-items {
										margin-top: 8px;
								}
								.selectric {
										text-align: right;
								}
						}
				}
		}
		&-therms {
				margin-top: 15px;
				@include font-size(16px);
				input[type="checkbox"]{
						display:none;
						& +label{
								position:relative;
								padding:0 0 10px 30px;
								margin:0;
								display: inline-block;
								&:before{
										content:"";
										width:22px;
										height:22px;
										background: #fff;
										display:block;
										position:absolute;
										left:0;
										top:1px;
										border:3px solid #000;
										@include transition;
								}
						}
						&:checked{
								& +label{
										&:before{
												border: 2px solid $color-secondary;
												background-color: $color-secondary;
										}
								}
						}
				}
		}
		&-submit {
				margin-top: 20px;
				@include media(md) {
						margin-top: 30px;
				}
		}
}

.column-padding-add {
		@include media(lg) {
				padding-left: 30px;
		}
}

.margin-b-xxs-md {
		@include media(xxs, md) {
				margin-bottom: 20px;
		}
}

.insert-image {
		text-align: center;
		img {
			max-width: 100%;
		}
}


.safety-block {
		margin: 30px 0;
		h3 {
				font-weight: 600;
		}
}

.afi-gallery {
    &-item {
        padding-left: 5px;
        padding-bottom: 5px;
        flex: 0 1 100%;
        max-width: 100%;
        @include media(xs) {
        	   flex: 0 1 50%;
             max-width: 50%;
        }
        @include media(lg) {
            flex: 0 1 33.333%;
            max-width: 33.333%;
        }
        &-inner {
            position: relative;
            overflow: hidden;
            display: block;
            &:before {
                content: "";
                display: block;
                padding-top: 80%;
            }
            img {
                position: absolute;
                top: 0%;
                left: 50%;
                transform: translate(-50%, 0%);
                min-width: 100%;
                min-height: 100%;
                object-fit: cover;
                object-position: top;
            }
        }
    }
    &.large-space {
        .afi-gallery-item {
            padding-bottom: 40px;
        }
    }
}
