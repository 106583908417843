$path-font: "../fonts";

$color-black: #000;
$color-white: #fff;
$color-grey:  #999;

$color-primary: #009641;
$color-secondary: #f7403b;
$color-secondary-light: #e0e0e0;

$color-bg: #000000;
$color-hover: #797777;
$color-red: #ff5445;
$color-blue: #365fd6;
$color-green: #30a24e;


$color-base: #000;
$color-link: $color-secondary;
$color-link-hover: darken($color-secondary, 8);

$color-border: #e0e0e0;
$color-inactive: #f0f0f0;

$font-size: 16px;
$max-page-width: 1290px;

$font-base: Arial, Helvetica, sans-serif;

$transition-function:			ease;
$transition-duration: 			250ms;

$btn-bg:						$color-white;
$btn-border: 				$color-black;
$btn-color: 				$color-black;

$btn-bg-hover: 			darken($btn-bg,15%);
$btn-border-hover: 	darken($btn-border,10%);

$btn-height: 				40px;
$btn-border-width: 	1px;

$input-light: #f2f2f2;
$input-dark: #c7c7c7;
$input-darken: #7a7a7a;

//arrays
$share-icon-names: ( 
  google: "\e915",
  tumblr: "\e916",
  facebook: "\e914",
  mail: "\e911",
  twitter: "\e912",
  linkedin: "\e910",
  vine: "\e913",
  youtube: "\e906"
);


$password-strength: (
		weak: #B22222,
		acceptable: #F4A460,
		average: #4169E1,
		strong: #3CB371,
		excellent: #228B22
);

$px-values: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100);
