
@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.main-slider{
    width:100%;
    position:relative;
    &-item{
        @include flex-block;
        @include justify-content(center);
        @include align-items(center);
        width:100%;
        background-position:top center !important;
        background-repeat:no-repeat !important;
        @include background-size(cover);
        &-title{
            font-size: 35px;
            line-height: 40px;
            font-weight:600; 
            text-align:center;
						padding: 0 15px;
            color: $color-white;
						font-family: 'Druk-Wide';
            @include media(sm){
                font-size: 40px;
                line-height: 45px;
            }
            @include media(md){
                font-size: 55px;
                line-height: 60px;
            }
            @include media(lg){
                font-size: 65px;
                line-height: 70px;
								text-align: left;
								padding: 30px 15px;
								max-width: 40%;
            }
            @include media(xl){
                font-size: 70px;
                line-height: 75px;
            }
            @include media(xxl){
                font-size: 92px;
                line-height: 100px;
								padding: 30px 50px;
            }
        }
				&-title-bottom {
						margin-top: 30px;
						text-align: center;
						padding: 0 15px;
						@include font-size(26px);
						line-height: 32px;
						color: #fff;
						@include media(md) {
								margin-top: 50px;
						}
						@include media(lg) {
								text-align: left;
								padding: 30px 15px;
								max-width: 40%;
								@include font-size(36px);
								line-height: 43px;
						}
						@include media(xxl) {
								padding: 30px 50px;
						}
				}
        &-inner{
            @include flex-block;
            min-height:350px;
						@include flex-flow(column);
						@include justify-content(center);
						@include align-items(center);
						padding: 40px 0;

            @include media(sm){
                min-height:400px;
            }
            @include media(md){
                height:500px;
            }
            @include media(lg){
                height:715px;
								@include flex-flow(column);
								@include justify-content(space-between);
								@include align-items(flex-start);
								padding: 0;
            }
        }
    }
    .slick-dots{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15px;
        text-align: center;
        padding:0;
        margin:0;
        li{
            display: inline-block;
            vertical-align: middle;
            margin: 0 4px;
            width: 15px;
            height: 15px;
            cursor: pointer;
            background: transparent;
						border: 3px solid $color-white;
            @include border-radius(50%);
            @include animate();
            &:hover{
                background: $color-black;
            }
            button{
                display: none;
            }
            &.slick-active{
                background-color: $color-white;
            }
        }
    }
}

.hp-card {
		&-container {
				margin-top: 30px;
				@include media(md) {
						margin-top: 40px;
				}
				h2 {
						margin-bottom: 0;
						margin-right: 20px;
				}
				.hp-card-slider {
						overflow: hidden;
				}
				.slick-list {
						margin-left: -15px;
						margin-right: -15px;
						@include media(lg) {
								margin-left: -20px;
								margin-right: -20px;
						}
						.slick-slide {
								padding-left: 15px;
								padding-right: 15px;
								overflow: hidden;
								@include media(lg) {
										padding-left: 20px;
									  padding-right: 20px;
								}
						}
				}
				.slick-arrow {
					position: absolute;
					top: 50%;
					width: 20px;
					height: 40px;
					transform: translateY(-50%);
					@include font-size(0px);
					cursor: pointer;
					z-index: 99;
					border: 0;
					background: transparent;
					&:before {
							@include icomoon;
							content: "\e920";
							position: relative;
							display: inline-block;
							color: rgba($color-black, .4);
							font-weight: 600;
							@include font-size(40px);
							width: 20px;
							height: 40px;
							border-radius: 40px;
							@include transition(200ms);
							@include flex-block;
							@include justify-content(center);
							@include align-items(center);
					}
					&:focus,
					&:hover {
							outline: 0;
							border: 0;
							&:before {
									color: rgba($color-black, 1);
							}
					}
				}
				.slick-prev {
						left: 0;
				}
				.slick-next {
						right: 13px;
						&:before {
							content: "\e91f";
						}
				}
				.slick-disabled {
						&:before {
								opacity: 0;
						}
				}
		}
		&-title-box {
				margin-bottom: 30px;
				@include media(md) {
						margin-bottom: 40px;
				}
		}
}

.news-items {
		.slick-arrow {
				top: 20%;
				@include media(md) {
						top: 40%;
				}
				@include media(lg) {
						top: 50%;
				}
		}
}




.devider-title-link{
    text-decoration: none;
    color:#000;
    display:inline-block;
    @include flex-block;
    font-size: 16px;
    @include align-items(center);
    &:after{
        content:"\e908";
        background:transparent;
        @include border-radius(50%);
        border:1px solid #000;
        @include transition;
        width: 30px;
        height: 30px;
        @include font-size(30px);
        line-height:29px;
    }
    &:focus,
    &:hover{
        color: $color-black;
        &:after{
            background: $color-link;
            color:#fff;
            border:1px solid $color-link;
        }
    }
    span{
        display:none;
    }
    @include media(xs){
				span {
						display: inline-block;
						margin:0 0 0 5px;
				}
				&:after {
						margin:2px 0 0 10px;
				}
    }
    @include media(md){
        h2{
            font-size: 27px;
            line-height: 30px;
        }
        font-size: 20px;
        &:after{
            width: 30px;
            height: 30px;
            @include font-size(30px);
            line-height:29px;
        }
    }
    @include media(lg){
        padding: 15px 0;
        h2{
            font-size: 30px;
            line-height: 34px;
        }
    }
    @include media(xl){
        h2{
            font-size: 35px;
        }
        &:after{
            width:34px;
            height:34px;
            @include font-size(34px);
            line-height:33px;
						position: relative;
						top: 2px;
        }
    }
    @include media(xxl){
        padding: 20px 0;
        h2{
            font-size: 41px;
            line-height: 1;
        }
        font-size: 22px;
        line-height: 1;
        color: $color-black;
    }
}



.hp-project {
		&-item {
				height: 350px !important;
				background-position: center !important;
				background-size: cover !important;
				width: 100%;
				display: flex !important;
				@include flex-flow(column);
				@include justify-content(flex-end);
				@include media(md) {
						height: 400px !important;
				}
				@include media(lg) {
						height: 440px !important;
				}
				.h2 {
						color: $color-white;
						padding: 0 0 10px 20px;
						@include transition;
						@include media(lg) {
								padding: 0 0 20px 30px;
						}
				}
				&:hover,
				&:focus {
						.h2 {
								color: $color-link;
						}
				}
		}
}
