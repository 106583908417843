
@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";

.side-bar-contact {
		margin-top: 40px;
		margin-bottom: 40px;
		@include font-size(16px);
		line-height: 22px;
		@include media(md) {
				margin-top: 60px;
				margin-bottom: 0;
		}
		@include media(lg) {
				@include font-size(18px);
				line-height: 24px;
		}
		@include media(xxl) {
				margin-top: 80px;
		}
		h2 {
				margin-bottom: 8px;
				@include media(md,md) {
						@include font-size(24px);
				}
		}
}

.embed-video.small {
		&:before {
				padding-top: 55%;
		}
}

.embed-video.small-16{
		&:before {
				padding-top: 45%;
		}
}

.embed-video {
		position: relative;
		&:before {
				content: "";
				display: block;
				padding-top: 65%;
				width: 100%;
		}
}

.embed-video.large-16 {
		&:before {
				padding-top: 60%;
		}
}

.embed-video iframe {
    width: 100%;
    position:absolute;
    top:0;
    left:0;
}

.embed-video-overflow.autoplay-1,
.embed-video-overflow.played {
    opacity: 0;
    visibility: hidden;
    @include transition;
}

.embed-video-overflow {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: -1px;
    background-color: #252525;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    &.in-popup{
        display:block;
    }
		&:focus,
		&:hover {
				border: 0;
				outline: 0;
		}
}

.embed-video-icon {
    display: block;
    height: 90px;
    width: 90px;
    border: 2px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    @include transition;
}

.embed-video-icon:before {
    content:"";
    display: inline-block;
    font-size: 32px;
    width: 0;
    height: 0;
    @include triangle-right(20px, 20px, 30px, #fff);
    @include transition;
    top:22px;
    left:35px;
    position:absolute;
}
